import React, { Fragment, useEffect, useState } from "react";
import "./Cards.scss";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Skeleton } from "@mui/material";

import moment from "moment";
import "moment/locale/ka";
import "moment/locale/en-gb";
import "moment/locale/ru";

import { useMoney } from "../../../helper/useMoney";
import { getGeneralAnalytics } from "../../../store/analytics/analyticsSlice";

import CardSummary from "./CardSummary/CardSummary";
import CardProfit from "./CardProfit/CardProfit";
import DDropdown from "../../FilterForms/DDropdown/DDropdown";

const Cards = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { getGeneralAnalyticsResult, getGeneralAnalyticsLoading } =
    useSelector((state) => state.analytics);

  const { ISO } = useSelector((state) => state.lang);
  moment.locale(ISO);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [date, setDate] = useState(new Date());

  const totalRevenue = useMoney(
    getGeneralAnalyticsResult?.revenue || 0,
    user?.currency?.symbol
  );
  const totalRevenueMonth = useMoney(1500 || "", user?.currency?.symbol);
  const profit = useMoney(getGeneralAnalyticsResult?.profit || 0, user?.currency?.symbol);
  const aov = useMoney(getGeneralAnalyticsResult?.aov || 0, user?.currency?.symbol);

  useEffect(() => {
    dispatch(getGeneralAnalytics(moment(date).format("YYYY-MM")));
  }, [date, dispatch]);

  return (
    <div id='Cards'>
      <div className='cardsHeader'>
        <div className='cardsHeaderTitle'>{t("tms.titles.Result")}</div>
        <DDropdown
          dropdownOpen={dropdownOpen}
          setDropdownOpen={setDropdownOpen}
          placeholder={moment(date).format("MMMM")}
        >
          <Calendar
            className='calendar'
            onChange={(date) => setDate(date)}
            value={date}
            minDetail='year'
            maxDetail='year'
            formatMonth={(_, date) => moment(date).format("MMM")}
            tileDisabled={({ date, view }) =>
              view === "year" &&
              (date.getFullYear() > new Date().getFullYear() ||
                (date.getFullYear() === new Date().getFullYear() &&
                  date.getMonth() > new Date().getMonth()))
            }
            onClickMonth={() => setDropdownOpen(false)}
          />
        </DDropdown>
      </div>
      <div className='cardsContainer'>
        {getGeneralAnalyticsLoading ? (
          <Fragment>
            <Skeleton variant='rounded' className='skeleton' />
            <Skeleton variant='rounded' className='skeleton' />
            <Skeleton variant='rounded' className='skeleton' />
            <Skeleton variant='rounded' className='skeleton' />
          </Fragment>
        ) : (
          <Fragment>
            <CardSummary
              title={t("tms.desc.Revenue")}
              number={totalRevenue}
              month={totalRevenueMonth}
              lastMonth={parseFloat(
                getGeneralAnalyticsResult?.revenue_increase_percent
              ).toFixed(2)}
            />
            <CardProfit
              title={t("tms.desc.Profit")}
              titleNumber={profit}
              desc={t("tms.desc.Profit Ratio")}
              profitRatio={parseFloat(getGeneralAnalyticsResult?.profit_ratio).toFixed(2)}
            />
            <CardSummary
              title={t("tms.desc.Order completed")}
              number={`${parseFloat(
                getGeneralAnalyticsResult?.completed_orders || 0
              ).toFixed(2)}%`}
              month={"68%"}
              lastMonth={parseFloat(
                getGeneralAnalyticsResult?.completed_orders_increase_percent
              ).toFixed(2)}
            />
            <CardProfit
              title={t("tms.desc.AOV")}
              titleNumber={aov}
              desc={t("tms.desc.Number of shipments")}
              shipmentNumber={getGeneralAnalyticsResult?.order_count || 0}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Cards;
