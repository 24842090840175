import React, { useState, useEffect } from "react";
import "./AddOrder.scss";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { createOrder, resetCreateOrder } from "../../../../../store/orders/ordersSlice";

import DBackButton from "../../../../../componentsDashboard/Form/DButtons/DBackButton/DBackButton";
import Wrapper from "../../../../../componentsAdditional/Wrapper";
import RouteNewOrder from "../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/RouteNewOrder/RouteNewOrder";
import TrailersNewOrder from "../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/TrailersNewOrder/TrailersNewOrder";
import PaymentNewOrder from "../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/PaymentNewOrder/PaymentNewOrder";
import AditionalInformation from "../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/AditionalInformation/AditionalInformation";
import ProductNewOrder from "../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/ProductNewOrder/ProductNewOrder";
import DButton from "../../../../../componentsDashboard/Form/DButtons/DButton/DButton";

const AddOrder = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createOrderLoading, createOrderResult, createOrderError } = useSelector(
    (state) => state.orders
  );

  const { user } = useSelector((state) => state.auth);
  const { getCurrenciesSelect } = useSelector((state) => state.settings);

  const [customer, setCustomer] = useState(null);
  const [productType, setProductType] = useState(null);
  const [packingType, setPackingType] = useState(null);
  const [adr, setAdr] = useState(false);
  const [adrUnNumber, setAdrUnNumber] = useState("");
  const [adrClass, setAdrClass] = useState("");
  ///////
  const [locations, setLocations] = useState([
    { direction: "A", inputted_address: "", error: null },
    { direction: "B", inputted_address: "", error: null },
  ]);
  const [pickUpDate, setPickUpDate] = useState(null);
  const [pickUpTime, setPickUpTime] = useState(null);
  const [dropOffShow, setDropOffShow] = useState(false);
  const [dropOffDate, setDoprOffDate] = useState(null);
  const [dropOffTime, setDropOffTime] = useState(null);
  //////
  const [group, setGroup] = useState([
    {
      trailerType: null,
      trailerTypeError: null,
      trailerSize: null,
      trailerSizeError: null,
      temperatureRange: "",
      temperatureRangeError: null,
      weight: "",
      weightError: null,
      price: "",
      priceError: null,
      currency: null,
      currencyError: null,
    },
  ]);
  //////
  const [payMethod, setPayMethod] = useState(null);
  const [payMethodSub, setPayMethodSub] = useState(null);
  const [payMethodAmount, setPayMethodAmount] = useState("");
  const [payMethodAmountCurrency, setPayMethodAmountCurrency] = useState(null);
  const [payConsignationPeriod, setPayConsignationPeriod] = useState("");
  ////
  const [comment, setComment] = useState("");

  // catch locations error
  useEffect(() => {
    if (createOrderError?.errors) {
      const updatedLocations = locations.map((location, index) => ({
        ...location,
        error:
          createOrderError.errors[`0.cargos.0.locations.${index}.inputted_address`]?.[0] ?? null,
      }));
      setLocations(updatedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderError]);

  // catch group errors
  useEffect(() => {
    if (createOrderError?.errors) {
      const updatedGroup = group.map((item, index) => ({
        ...item,
        trailerTypeError:
          createOrderError?.errors?.[`${index}.cargos.0.trailer_type_id`]?.[0] || null,
        trailerSizeError:
          createOrderError?.errors?.[`${index}.cargos.0.trailer_size_id`]?.[0] || null,
        temperatureRangeError:
          createOrderError?.errors?.[`${index}.cargos.0.temperature`]?.[0] || null,
        weightError: createOrderError?.errors?.[`${index}.cargos.0.weight`]?.[0] || null,
        priceError: createOrderError?.errors?.[`${index}.revenue`]?.[0] || null,
        currencyError: createOrderError?.errors?.[`${index}.revenue_currency_id`]?.[0] || null,
      }));
      setGroup(updatedGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderError]);

  // select default user currency value
  useEffect(() => {
    if (getCurrenciesSelect && user.currency && user.currency.id) {
      const findValue = getCurrenciesSelect.find((item) => item.value.id === user.currency.id);
      setGroup((state) => state.map((i) => ({ ...i, currency: findValue })));
      setPayMethodAmountCurrency(findValue);
    }
  }, [getCurrenciesSelect, user.currency]);

  // reset
  useEffect(() => {
    if (createOrderResult) {
      dispatch(resetCreateOrder());
      navigate("/dashboard/tms/orders?page=1");
    }
  }, [dispatch, navigate, createOrderResult]);

  // reset all time
  useEffect(() => {
    return () => {
      dispatch(resetCreateOrder());
    };
  }, [dispatch]);

  const submitHandler = () => {
    const data = group.map((item) => ({
      branch_id: user?.branch_id || null,
      platform_id: null,
      priority_id: 1,
      transfer_type_id: 1,
      type_id: 2,
      sale_manager_id: user?.id || null,
      logistic_manager_id: null,
      client_id: customer?.value?.id || null,
      revenue: item?.price || 0,
      revenue_currency_id: item?.currency?.value?.id || null,
      client_payment: {
        type_id: payMethod?.value?.id || null,
        amount: payMethodAmount || null,
        currency_id: payMethodAmountCurrency?.value?.id || null,
        option_type: payMethodSub?.value?.id || null,
        days: payConsignationPeriod || null,
      },
      about: comment || null,
      comment: null,
      deadline_date: null,
      services: null,
      documents: [],
      cargos: [
        {
          trailer_type_id: item?.trailerType?.value?.id || null,
          trailer_size_id: item?.trailerSize?.value?.id || null,
          temperature: item?.temperatureRange || null,
          type_id: productType?.value?.id || null,
          packing_type_id: packingType?.value?.id || null,
          count: 1,
          weight: item?.weight || null,
          volume: null,
          adr: adr || false,
          adr_class: adrClass || null,
          un_number: adrUnNumber || null,
          hs_code: null,
          oversized: false,
          price: null,
          price_currency_id: null,
          cargo_ready_date: null,

          pickup_date: pickUpDate ? moment(pickUpDate).format("YYYY-MM-DD") : null,
          pickup_time_start: pickUpTime ? pickUpTime[0] : null,
          pickup_time_end: pickUpTime ? pickUpTime[1] : null,
          dropoff_date: dropOffDate ? moment(dropOffDate).format("YYYY-MM-DD") : null,
          dropoff_time_start: dropOffTime ? dropOffTime[0] : null,
          dropoff_time_end: dropOffTime ? dropOffTime[1] : null,

          locations: locations.map((loc) => ({
            direction: loc?.direction || null,
            type: "LOCALITY",
            inputted_address: loc?.inputted_address || null,
          })),
        },
      ],
    }));
    dispatch(createOrder(data));
  };

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Create Order")}>
      <section id='createNewOrderFromCompany'>
        <div className='header'>
          <DBackButton alt='/dashboard/tms/orders?page=1' />
        </div>
        <form>
          <ProductNewOrder
            customer={customer}
            setCustomer={setCustomer}
            customerError={createOrderError?.errors?.["0.client_id"]?.[0] ?? null}
            //////
            productType={productType}
            setProductType={setProductType}
            productTypeError={createOrderError?.errors?.["0.cargos.0.type_id"]?.[0] ?? null}
            //////
            packingType={packingType}
            setPackingType={setPackingType}
            packingTypeError={createOrderError?.errors?.["0.cargos.0.packing_type_id"]?.[0] ?? null}
            /////
            adr={adr}
            setAdr={setAdr}
            adrError={createOrderError?.errors?.["0.cargos.0.adr"]?.[0] ?? null}
            ////
            adrUnNumber={adrUnNumber}
            setAdrUnNumber={setAdrUnNumber}
            adrUnNumberError={createOrderError?.errors?.["0.cargos.0.un_number"]?.[0] ?? null}
            ////
            adrClass={adrClass}
            setAdrClass={setAdrClass}
            adrClassError={createOrderError?.errors?.["0.cargos.0.adr_class"]?.[0] ?? null}
          />
          <RouteNewOrder
            locations={locations}
            setLocations={setLocations}
            /////
            pickUpDate={pickUpDate}
            setPickUpDate={setPickUpDate}
            pickUpDateError={createOrderError?.errors?.["0.cargos.0.pickup_date"]?.[0] ?? null}
            /////
            pickUpTime={pickUpTime}
            setPickUpTime={setPickUpTime}
            pickUpTimeError={
              (createOrderError?.errors?.["0.cargos.0.pickup_time_start"]?.[0] ?? null) ||
              (createOrderError?.errors?.["0.cargos.0.pickup_time_end"]?.[0] ?? null)
            }
            /////
            dropOffShow={dropOffShow}
            setDropOffShow={setDropOffShow}
            dropOffDate={dropOffDate}
            setDropOffDate={setDoprOffDate}
            dropOffDateError={createOrderError?.errors?.["0.cargos.0.dropoff_date"]?.[0] ?? null}
            //////
            dropOffTime={dropOffTime}
            setDropOffTime={setDropOffTime}
            dropOffTimeError={
              (createOrderError?.errors?.["0.cargos.0.dropoff_time_start"]?.[0] ?? null) ||
              (createOrderError?.errors?.["0.cargos.0.dropoff_time_end"]?.[0] ?? null)
            }
          />
          <TrailersNewOrder group={group} setGroup={setGroup} />
          <PaymentNewOrder
            payMethod={payMethod}
            setPayMethod={setPayMethod}
            payMethodError={createOrderError?.errors?.["0.client_payment.type_id"]?.[0] ?? null}
            ////
            payMethodSub={payMethodSub}
            setPayMethodSub={setPayMethodSub}
            payMethodSubError={
              createOrderError?.errors?.["0.client_payment.option_type"]?.[0] ?? null
            }
            /////
            payMethodAmount={payMethodAmount}
            setPayMethodAmount={setPayMethodAmount}
            payMethodAmountError={
              createOrderError?.errors?.["0.client_payment.amount"]?.[0] ?? null
            }
            /////
            payMethodAmountCurrency={payMethodAmountCurrency}
            setPayMethodAmountCurrency={setPayMethodAmountCurrency}
            payMethodAmountCurrencyError={
              createOrderError?.errors?.["0.client_payment.currency_id"]?.[0] ?? null
            }
            /////
            payConsignationPeriod={payConsignationPeriod}
            setPayConsignationPeriod={setPayConsignationPeriod}
            payConsignationPeriodError={
              createOrderError?.errors?.["0.client_payment.days"]?.[0] ?? null
            }
          />

          <AditionalInformation
            comment={comment}
            setComment={setComment}
            commentError={createOrderError?.errors?.["0.about"]?.[0] ?? null}
          />

          <div className='submitContainer'>
            <DButton
              loading={createOrderLoading}
              title={t("tms.forms.buttons.Create order")}
              onClick={() => submitHandler()}
            />
          </div>
        </form>
      </section>
    </Wrapper>
  );
};

export default AddOrder;
