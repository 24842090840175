import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { MdLocationPin } from "react-icons/md";
import { PiAirplaneTiltFill } from "react-icons/pi";
import { useTranslation } from "react-i18next";

import {
  getDropoffAIRPORT,
  getDropoffLOCALITY,
  getPickupAIRPORT,
  getPickupLOCALITY,
  resetGetDropoffAIRPORT,
  resetGetDropoffLOCALITY,
  resetGetPickupAIRPORT,
  resetGetPickupLOCALITY,
} from "../../../../../store/locationSuggestion/locationSuggestionSlice";

import CreateOrderLocation from "../../Components/CreateOrderLocation/CreateOrderLocation";

const AirLocation = ({ locations, setLocations, edit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getPickupLOCALITY(user?.client_id));
    dispatch(getDropoffLOCALITY(user?.client_id));
    dispatch(getPickupAIRPORT(user?.client_id));
    dispatch(getDropoffAIRPORT(user?.client_id));
    return () => {
      dispatch(resetGetPickupLOCALITY());
      dispatch(resetGetDropoffLOCALITY());
      dispatch(resetGetPickupAIRPORT());
      dispatch(resetGetDropoffAIRPORT());
    };
  }, [dispatch, user?.client_id]);
  const locationADesc = [
    {
      type: "AIRPORT",
      name: t("shipper.forms.content.Pick up the container at the airport"),
      icon: <PiAirplaneTiltFill />,
    },

    {
      type: "LOCALITY",
      name: t("shipper.forms.content.Pick up the container at a facility"),
      icon: <MdLocationPin />,
    },
  ];

  const locationBDesc = [
    {
      type: "AIRPORT",
      name: t("shipper.forms.content.Deliver the container to the airport"),
      icon: <PiAirplaneTiltFill />,
    },

    {
      type: "LOCALITY",
      name: t("shipper.forms.content.Deliver the container to a facility (door)"),
      icon: <MdLocationPin />,
    },
  ];

  return (
    <CreateOrderLocation
      edit={edit}
      type='AIRPORT'
      locations={locations}
      setLocations={setLocations}
      locationADesc={locationADesc}
      locationBDesc={locationBDesc}
      title={t("shipper.forms.titles.Location details")}
    />
  );
};

export default AirLocation;
