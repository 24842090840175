import React, { Fragment, useState, useEffect } from "react";
import "./EditPrePaymentModal.scss";

import { useTranslation } from "react-i18next";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidDollarCircle } from "react-icons/bi";

import {
  updatePayment,
  resetUpdatePayment,
} from "../../../../../../store/orders/orderFinance/orderFinanceSlice";

import DModal from "../../../../../Modal/DModal/DModal";
import DCurrencyInput from "../../../../../Form/DCurrencyInput/DCurrencyInput";
import DDatepicker from "../../../../../Form/DDatepicker/DDatepicker";
import DTextarea from "../../../../../Form/DTextarea/DTextarea";

const EditPrePaymentModal = ({ isOpen, isClose, order, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { updatePaymentLoading, updatePaymentResult, updatePaymentError } = useSelector(
    (state) => state.orderFinance
  );

  const [amount, setAmount] = useState(item?.amount || "");
  const [currencyValue, setCurrencyValue] = useState(
    item?.currency
      ? {
          label: item?.currency?.code,
          value: item?.currency,
        }
      : null
  );
  const [paymentDate, setPaymentDate] = useState(
    item.created_at ? moment(item.created_at, "DD-MM-YYYY").toDate() : null
  );
  const [comment, setComment] = useState(item?.comment || "");

  const submitHandler = () => {
    const data = {
      orderId: order.id,
      paymentId: item.id,

      currency_id: currencyValue?.value?.id || null,
      amount: amount || null,
      payment_date: paymentDate ? moment(paymentDate).format("YYYY/MM/DD") : null,
      comment: comment || null,
    };

    dispatch(updatePayment(data));
  };

  useEffect(() => {
    if (updatePaymentResult) {
      dispatch(resetUpdatePayment());
    }
  }, [dispatch, updatePaymentResult]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdatePayment());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Edit pre payment")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<BiSolidDollarCircle />}
        status='success'
        onClick={() => submitHandler()}
        loading={updatePaymentLoading}
      >
        <div id='EditPrePaymentModal'>
          <div className='EditPrePaymentModalGroup'>
            <DCurrencyInput
              id={"AddPaymentAmount"}
              label={t("tms.modals.labels.Amount")}
              placeholder={t("tms.modals.placeholder.Enter paid money")}
              value={amount}
              setValue={setAmount}
              currencyValue={currencyValue}
              setCurrencyValue={setCurrencyValue}
              required
              error={
                (updatePaymentError?.errors?.amount?.[0] ?? null) ||
                (updatePaymentError?.errors?.currency_id?.[0] ?? null)
              }
            />
            <DDatepicker
              label={t("tms.modals.labels.Payment date")}
              placeholder={t("tms.modals.placeholder.Current date")}
              value={paymentDate}
              setValue={setPaymentDate}
              required
              error={updatePaymentError?.errors?.payment_date?.[0] ?? null}
            />
            <DTextarea
              id={"AddPaymentModalTextAreaAdvances"}
              value={comment}
              setValue={setComment}
              label={t("tms.modals.labels.Comment")}
              placeholder={t("tms.modals.placeholder.Enter comment")}
              error={updatePaymentError?.errors?.comment?.[0] ?? null}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default EditPrePaymentModal;
