import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import routesService from "./routesService";

const initialState = {
  getRoutesListLoading: true,
  getRoutesListResult: null,
  getRoutesListError: null,

  getRoutesRoadListLoading: true,
  getRoutesRoadListResult: null,
  getRoutesRoadListError: null,
};

export const getRoutesList = createAsyncThunk("routes/getRoutesList", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await routesService.getRoutesList(ISO, TOKEN);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getRoutesRoadList = createAsyncThunk(
  "routes/getRoutesRoadList",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await routesService.getRoutesList(ISO, 1);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const routesSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoutesList.pending, (state) => {
        state.getRoutesListResult = null;
        state.getRoutesListLoading = true;
        state.getRoutesListError = null;
      })
      .addCase(getRoutesList.fulfilled, (state, action) => {
        state.getRoutesListResult = action.payload;
        state.getRoutesListLoading = false;
        state.getRoutesListError = null;
      })
      .addCase(getRoutesList.rejected, (state, action) => {
        state.getRoutesListResult = null;
        state.getRoutesListLoading = false;
        state.getRoutesListError = action.payload;
      })

      .addCase(getRoutesRoadList.pending, (state) => {
        state.getRoutesRoadListResult = null;
        state.getRoutesRoadListLoading = true;
        state.getRoutesRoadListError = null;
      })
      .addCase(getRoutesRoadList.fulfilled, (state, action) => {
        state.getRoutesRoadListResult = action.payload;
        state.getRoutesRoadListLoading = false;
        state.getRoutesRoadListError = null;
      })
      .addCase(getRoutesRoadList.rejected, (state, action) => {
        state.getRoutesRoadListResult = null;
        state.getRoutesRoadListLoading = false;
        state.getRoutesRoadListError = action.payload;
      });
  },
});

export default routesSlice.reducer;
