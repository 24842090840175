import React, { useState, useEffect } from "react";
import "./CreateAirOrder.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { createOrder, resetCreateOrder } from "./../../../../../../store/orders/ordersSlice";

import Wrapper from "./../../../../../../componentsAdditional/Wrapper";
import AirTopMenu from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperAir/AirTopMenu/AirTopMenu";
import AirLocation from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperAir/AirLocation/AirLocation";
import AirPackage from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperAir/AirPackage/AirPackage";
import CreateOrderSubmit from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderSubmit/CreateOrderSubmit";
import CreateOrderHeader from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderHeader/CreateOrderHeader";
import CreateOrderAditionalInformation from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalInformation/CreateOrderAditionalInformation";
import CreateOrderAditionalServices from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalServices/CreateOrderAditionalServices";

const CreateAirOrder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { createOrderLoading, createOrderResult, createOrderError } = useSelector(
    (state) => state.orders
  );

  const [locations, setLocations] = useState([
    {
      direction: "A",
      inputted_address: "",
      type: "AIRPORT",
      error: null,
    },
    {
      direction: "B",
      inputted_address: "",
      type: "AIRPORT",
      error: null,
    },
  ]);
  //////// group
  const [group, setGroup] = useState([
    {
      cargoType: null,
      cargoTypeError: null,
      packageType: null,
      packageTypeError: null,
      HSCode: false,
      HSCodeValue: "",
      HSCodeValueError: null,
      //////
      adr: false,
      adrError: null,
      adrNumber: "",
      adrNumberError: null,
      adrClass: "",
      adrClassError: null,
      adrFile: [],
      //////
      dimensions: [
        {
          count: 1,
          countError: null,
          weight: "",
          weightError: null,
          length: "",
          lengthError: null,
          width: "",
          widthError: null,
          height: "",
          heightError: null,
        },
      ],
    },
  ]);
  ///// Additional Services
  const [selectedServices, setSelectedServices] = useState([]);
  ///// Additional information
  const [cargoPrice, setCargoPrice] = useState("");
  const [cargoPriceCurrency, setCargoPriceCurrency] = useState(
    user
      ? {
          label: user?.currency?.code || "",
          value: user?.currency || null,
        }
      : null
  );
  const [readyCargoTime, setReadyCargoTime] = useState(null);
  const [comment, setComment] = useState("");
  const [aditionalDocument, setAditionalDocument] = useState([]);

  // catch locations error
  useEffect(() => {
    if (createOrderError?.errors) {
      const updatedLocations = locations.map((location, i) => ({
        ...location,
        error: createOrderError.errors[`0.cargos.0.locations.${i}.inputted_address`]?.[0] ?? null,
      }));
      setLocations(updatedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderError]);

  // catch group errors
  useEffect(() => {
    if (createOrderError?.errors) {
      const updatedGroup = group.map((item, i) => ({
        ...item,
        packageTypeError: createOrderError?.errors?.[`0.cargos.${i}.packing_type_id`]?.[0] || null,
        cargoTypeError: createOrderError?.errors?.[`0.cargos.${i}.type_id`]?.[0] || null,
        HSCodeValueError: createOrderError?.errors?.[`0.cargos.${i}.hs_code`]?.[0] || null,
        adrError: createOrderError?.errors?.[`0.cargos.${i}.adr`]?.[0] || null,
        adrNumberError: createOrderError?.errors?.[`0.cargos.${i}.un_number`]?.[0] || null,
        adrClassError: createOrderError?.errors?.[`0.cargos.${i}.adr_class`]?.[0] || null,

        dimensions: item.dimensions.map((dim, ind) => ({
          ...dim,
          countError:
            createOrderError?.errors?.[`0.cargos.${i}.dimensions.${ind}.count`]?.[0] || null,
          weightError:
            createOrderError?.errors?.[`0.cargos.${i}.weight`]?.[0] || null,
          lengthError:
            createOrderError?.errors?.[`0.cargos.${i}.dimensions.${ind}.length`]?.[0] || null,
          widthError:
            createOrderError?.errors?.[`0.cargos.${i}.dimensions.${ind}.width`]?.[0] || null,
          heightError:
            createOrderError?.errors?.[`0.cargos.${i}.dimensions.${ind}.height`]?.[0] || null,
        })),
      }));
      setGroup(updatedGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderError]);

  // reset
  useEffect(() => {
    if (createOrderResult) {
      dispatch(resetCreateOrder());
      navigate("/dashboard/shipper/orders?page=1");
    }
  }, [dispatch, navigate, createOrderResult]);

  // reset all time
  useEffect(() => {
    return () => {
      dispatch(resetCreateOrder());
    };
  }, [dispatch]);

  const getNextDate = () => {
    const today = new Date();
    const nextDate = new Date(today);
    nextDate.setDate(today.getDate() + 7);
    const formattedDate = nextDate.toISOString().split("T")[0];
    return formattedDate;
  };

  // submit
  const submitHandler = () => {
    let documents = [];
    group.forEach((g) => {
      const adrFileIds = g.adrFile.map((file) => file.id);
      documents = [...documents, ...adrFileIds];
    });
    const additionalDocumentIds = aditionalDocument.map((doc) => doc.id);
    documents = [...documents, ...additionalDocumentIds];

    const data = [
      {
        priority_id: 1,
        transfer_type_id: 1,
        type_id: 3,
        revenue: 0,
        revenue_currency_id: user?.currency?.id || null,
        client_payment: null,
        about: comment || null,
        services: selectedServices.map((i) => i.id),
        documents: documents,
        cargos: group.map((item) => {
          let totalWeight = 0;
          let totalVolume = 0;
          item.dimensions.forEach((i) => {
            const volume = parseFloat(
              (
                ((i?.length || 0) * (i?.width || 0) * (i?.height || 0) * (i?.count || 0)) /
                1000000
              ).toFixed(5)
            );
            const weight = parseFloat(((i?.weight || 0) * (i?.count || 0)).toFixed(2));
            totalWeight += weight;
            totalVolume += volume;
          });

          return {
            trailer_type_id: null,
            trailer_size_id: null,
            temperature: null,
            type_id: item?.cargoType?.value?.id || null,
            count: 1,
            weight: totalWeight || null,
            volume: totalVolume || null,
            packing_type_id: item?.packageType?.value?.id || null,
            adr: item?.adr ? true : false,
            adr_class: item?.adrClass || null,
            un_number: item?.adrNumber || null,
            hs_code: item?.HSCodeValue || null,
            oversized: false,
            oversized_call: null,
            price: cargoPrice || null,
            price_currency_id: cargoPriceCurrency?.value?.id || null,
            cargo_ready_date: readyCargoTime ? moment(readyCargoTime).format("YYYY-MM-DD") : null,
            pickup_date: readyCargoTime
              ? moment(readyCargoTime).format("YYYY-MM-DD")
              : getNextDate(),
            pickup_time_start: null,
            pickup_time_end: null,
            dropoff_date: null,
            dropoff_time_start: null,
            dropoff_time_end: null,
            locations: locations.map((loc) => ({
              direction: loc?.direction || null,
              type: loc?.type || "LOCALITY",
              inputted_address: loc?.inputted_address || null,
            })),
            dimensions: item.dimensions.map((i) => {
              const volume = parseFloat(
                (((i?.length || 0) * (i?.width || 0) * (i?.height || 0)) / 1000000).toFixed(5)
              );
              return {
                packing_type_id: i?.packingType?.value?.id || null,
                count: i?.count || 1,
                weight: i?.weight || 0,
                volume: volume || null,
                length: i?.length || null,
                width: i?.width || null,
                height: i?.height || null,
              };
            }),
          };
        }),
      },
    ];
    dispatch(createOrder(data));
  };

  return (
    <Wrapper documentTitle={t("shipper.titles.Air freight")}>
      <AirTopMenu locations={locations} group={group} time={readyCargoTime} />
      <section id='ShipperCreateOrderAir'>
        <div className='ShipperCreateOrderAirWrapper'>
          <CreateOrderHeader />
          <AirLocation locations={locations} setLocations={setLocations} />
          <AirPackage edit={false} group={group} setGroup={setGroup} />
          <CreateOrderAditionalServices
            title={t("shipper.forms.titles.Additional Services")}
            selectedServices={selectedServices}
            setSelectedServices={setSelectedServices}
          />
          <CreateOrderAditionalInformation
            edit={false}
            title={t("shipper.forms.titles.Additional information")}
            /////
            readyCargoTime={readyCargoTime}
            setReadyCargoTime={setReadyCargoTime}
            readyCargoTimeError={
              createOrderError?.errors?.["0.cargos.0.cargo_ready_date"]?.[0] ?? null
            }
            /////
            cargoPrice={cargoPrice}
            setCargoPrice={setCargoPrice}
            cargoPriceError={createOrderError?.errors?.["0.cargos.0.price"]?.[0] ?? null}
            /////
            cargoPriceCurrency={cargoPriceCurrency}
            setCargoPriceCurrency={setCargoPriceCurrency}
            cargoPriceCurrencyError={
              createOrderError?.errors?.["0.cargos.0.price_currency_id"]?.[0] ?? null
            }
            //////
            comment={comment}
            setComment={setComment}
            commentError={createOrderError?.errors?.["0.about"]?.[0] ?? null}
            //////
            aditionalDocument={aditionalDocument}
            setAditionalDocument={setAditionalDocument}
          />
          <CreateOrderSubmit
            title={t("shipper.buttons.Get a quote")}
            loading={createOrderLoading}
            onClick={() => submitHandler()}
          />
        </div>
      </section>
    </Wrapper>
  );
};

export default CreateAirOrder;
