import _request from "../_request";

const subscribeStep1 = async (ISO, data) => {
  const config = {
    url: "/subscribers",
    method: "post",
    ISO,
  };
  return _request(config, data);
};

const subscribeStep2 = async (ISO, data) => {
  const config = {
    url: "/subscribers",
    method: "post",
    ISO,
  };
  return _request(config, data);
};

const subscribeStep3 = async (ISO, data) => {
  const config = {
    url: "/subscribers",
    method: "post",
    ISO,
  };
  return _request(config, data);
};

const verifyCode = async (ISO, data) => {
  const config = {
    url: `/subscribers/verify`,
    method: "post",
    ISO,
  };
  return _request(config, data);
};

const sendOtp = async (ISO, data) => {
  const config = {
    url: "/subscribers/send-otp",
    method: "post",
    ISO,
  };
  return _request(config, data);
};

const subscribeService = {
  subscribeStep1,
  subscribeStep2,
  subscribeStep3,
  verifyCode,
  sendOtp,
};

export default subscribeService;
