import React from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setActiveOrderId,
  setActiveOrderIndex,
  setShipperAcceptedOrderView,
} from "../../../../../store/orders/shipperAccepted/shipperAcceptedSlice";

import RowField from "../../../../Form/TableFields/RowField/RowField";
import ProgressField from "../../../../Form/TableFields/ProgressField/ProgressField";
import NameField from "../../../../Form/TableFields/NameField/NameField";
import RouteField from "../../../../Form/TableFields/RouteField/RouteField";
import DateField from "../../../../Form/TableFields/DateField/DateField";
import OrderStatusShipperField from "../../../../Form/TableFields/OrderStatusField/OrderStatusShipperField";

const ActiveShipmentsTableItem = ({ order }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clickFieldHandler = () => {
    dispatch(setShipperAcceptedOrderView(false));
    dispatch(setActiveOrderId(order.id));
    dispatch(setActiveOrderIndex(0));
    navigate("/dashboard/shipper/accepted");
  };

  return (
    <RowField
      style={{
        borderLeft: `4px solid ${order.color ? order.color : "transparent"}`,
      }}
    >
      <NameField label={order?.tracking || "-"} onClick={() => clickFieldHandler()} />
      <OrderStatusShipperField status={order?.statusClient || null} />
      <RouteField order={order} />
      <DateField date={order?.cargos?.[0]?.pickup_date ?? null} />
      <ProgressField progress={order?.last_shipment_status_update?.distance_covered_percent || 0} />
    </RowField>
  );
};

export default ActiveShipmentsTableItem;
