import React, { useEffect, useState } from "react";
import "./CarrierSubscribeForm.scss";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getRoutesRoadList } from "../../../../store/routes/routesSlice";
import { getTrailerTypesRoad } from "../../../../store/trailerType/trailerTypeSlice";

import {
  resetSubscribeStep1,
  resetSubscribeStep2,
  resetSubscribeStep3,
  subscribeStep1,
  subscribeStep2,
  subscribeStep3,
} from "../../../../store/subscribe/subscribeSlice";

import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import Step4 from "./Step4/Step4";
import Step5 from "./Step5/Step5";

const CarrierSubscribeForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getTrailerTypesRoadResult } = useSelector((state) => state.trailerType);
  const { getRoutesRoadListResult } = useSelector((state) => state.routes);
  const {
    subscribeStep1Result,
    subscribeStep1Loading,
    subscribeStep2Result,
    subscribeStep2Loading,
    subscribeStep3Result,
    subscribeStep3Loading,
  } = useSelector((state) => state.subscribe);

  const { phoneIndexValueId } = useSelector((state) => state.settings);

  const [step, setStep] = useState(1);
  // Step 1
  const [switcherValue, setSwitcherValue] = useState("fleetOwner");
  const [radioValue, setRadioValue] = useState("1");
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [selectedTrailerTypes, setSelectedTrailerTypes] = useState([]);

  // Step 2
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [country, setCountry] = useState(null);

  // Step 3
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [communicationMethod, setCommunicationMethod] = useState({
    value: "phone",
    label: t("landing.landingCarrierSubscribe.On WhatsApp"),
  });

  const handleStep1Submit = () => {
    const data = {
      fleet_owner: switcherValue === "fleetOwner",
      fleet_quantity: radioValue,
      trailer_types: selectedTrailerTypes || [],
      routes: selectedRoutes || [],

      registration_country: null,
      company_name: null,
      company_code: null,

      fullname: null,
      email: null,
      phone: null,
      phone_index_id: null,
      contact_channel: null,
    };
    dispatch(subscribeStep1(data));
  };

  const handleStep2Submit = () => {
    const data = {
      fleet_owner: switcherValue === "fleetOwner",
      fleet_quantity: radioValue,
      trailer_types: selectedTrailerTypes || [],
      routes: selectedRoutes || [],

      registration_country: country?.value,
      company_name: companyName,
      company_code: companyId,

      fullname: null,
      email: null,
      phone: null,
      phone_index_id: null,
      contact_channel: null,
    };
    dispatch(subscribeStep2(data));
  };

  const handleStep3Submit = () => {
    const data = {
      fleet_owner: switcherValue === "fleetOwner",
      fleet_quantity: radioValue,
      trailer_types: selectedTrailerTypes || [],
      routes: selectedRoutes || [],

      registration_country: country?.value,
      company_name: companyName,
      company_code: companyId,

      fullname: name,
      email: email,
      phone: phone,
      phone_index_id: phoneIndexValueId,
      contact_channel: communicationMethod?.value,
    };
    dispatch(subscribeStep3(data));
  };

  useEffect(() => {
    if (subscribeStep1Result) {
      setStep((prev) => prev + 1);
    }
    return () => {
      dispatch(resetSubscribeStep1());
    };
  }, [dispatch, subscribeStep1Result]);

  useEffect(() => {
    if (subscribeStep2Result) {
      setStep((prev) => prev + 1);
    }
    return () => {
      dispatch(resetSubscribeStep2());
    };
  }, [dispatch, subscribeStep2Result]);

  useEffect(() => {
    if (subscribeStep3Result) {
      setStep((prev) => prev + 1);
    }
    return () => {
      dispatch(resetSubscribeStep3());
    };
  }, [dispatch, subscribeStep3Result]);

  useEffect(() => {
    if (!getTrailerTypesRoadResult) {
      dispatch(getTrailerTypesRoad());
    }
    if (!getRoutesRoadListResult) {
      dispatch(getRoutesRoadList());
    }
  }, [dispatch, getTrailerTypesRoadResult, getRoutesRoadListResult]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            switcherValue={switcherValue}
            setSwitcherValue={setSwitcherValue}
            radioValue={radioValue}
            setRadioValue={setRadioValue}
            selectedRoutes={selectedRoutes}
            setSelectedRoutes={setSelectedRoutes}
            selectedTrailerTypes={selectedTrailerTypes}
            setSelectedTrailerTypes={setSelectedTrailerTypes}
            handleSubmit={handleStep1Submit}
            loading={subscribeStep1Loading}
          />
        );
      case 2:
        return (
          <Step2
            setStep={setStep}
            companyName={companyName}
            setCompanyName={setCompanyName}
            companyId={companyId}
            setCompanyId={setCompanyId}
            country={country}
            setCountry={setCountry}
            handleSubmit={handleStep2Submit}
            loading={subscribeStep2Loading}
          />
        );
      case 3:
        return (
          <Step3
            setStep={setStep}
            name={name}
            setName={setName}
            phone={phone}
            setPhone={setPhone}
            email={email}
            setEmail={setEmail}
            communicationMethod={communicationMethod}
            setCommunicationMethod={setCommunicationMethod}
            handleSubmit={handleStep3Submit}
            loading={subscribeStep3Loading}
          />
        );
      case 4:
        return (
          <Step4
            step={step}
            setStep={setStep}
            method={communicationMethod?.value}
            phoneIndexId={phoneIndexValueId}
            phone={phone}
            email={email}
          />
        );
      case 5:
        return <Step5 />;
      default:
        break;
    }
  };

  return <div id='CarrierSubscribeForm'>{renderStep()}</div>;
};

export default CarrierSubscribeForm;
