import React, { Fragment, useState, useEffect } from "react";
import "./FillCarrierModal.scss";

import { useTranslation } from "react-i18next";

import { MdInfoOutline } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

import DModal from "../../../../Modal/DModal/DModal";
import DInputs from "../../../../Form/DInputs/DInputs";

import {
  createTransport,
  resetCreateTransport,
} from "./../../../../../store/orders/manageOrder/manageOrderSlice";

const FillCarrierModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { createTransportLoading, createTransportResult, createTransportError } = useSelector(
    (state) => state.manageOrder
  );

  const [number, setNumber] = useState("");

  const submitHandler = () => {
    const data = {
      orderId: order.id,
      data: [
        {
          number: number,
        },
      ],
    };
    dispatch(createTransport(data));
  };

  useEffect(() => {
    if (createTransportResult) {
      dispatch(resetCreateTransport());
    }
  }, [dispatch, createTransportResult]);

  useEffect(() => {
    return () => {
      dispatch(resetCreateTransport());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        submitText={t("tms.modals.buttons.Save")}
        title={t("tms.modals.titles.Truck number / Trailer number")}
        icon={<MdInfoOutline />}
        onClick={() => submitHandler()}
        loading={createTransportLoading}
      >
        <div id='FillCarrierModal'>
          <div className='FillCarrierModalGroup'>
            <DInputs
              id='truckRegistrationNumber'
              label={t("tms.modals.titles.Truck number / Trailer number")}
              placeholder={t("tms.modals.placeholder.EX: tx-456-tx / ty-456-ty")}
              value={number}
              setValue={setNumber}
              error={createTransportError?.errors?.["0.number"]?.[0] ?? null}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default FillCarrierModal;
