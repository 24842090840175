import React, { useEffect } from "react";
import "./Step2.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Title from "../Components/Title/Title";
import SubTitle from "../Components/SubTitle/SubTitle";
import ProgressBar from "../Components/ProgressBar/ProgressBar";
import Label from "../../../../Form/Label/Label";
import Input from "../../../../Form/Inputs/Input";
import SelectForm from "../../../../Form/Select/SelectForm";
import Footer from "../Components/Footer/Footer";
import ErrorText from "../../../../Form/ErrorText/ErrorText";

const Step2 = ({
  setStep,
  companyName,
  setCompanyName,
  companyId,
  setCompanyId,
  country,
  setCountry,
  loading,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const { getAllCountriesResult, getAllCountriesLoading, countryId, getCountriesResult } =
    useSelector((state) => state.settings);
  const { subscribeStep2Error } = useSelector((state) => state.subscribe);

  const options =
    getAllCountriesResult &&
    getAllCountriesResult.map((item) => ({
      value: item,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "15px",
              height: "15px",
              backgroundImage: `url(${item?.flag})`,
              backgroundPosition: "cover",
              backgroundRepeat: "no-repeat",
              marginRight: "5px",
            }}
          ></div>

          <p>{item?.name}</p>
        </div>
      ),
    }));

  const filterOption = (option, inputValue) => {
    if (option) {
      const { value } = option;
      return (
        value?.name?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
        value?.type?.name?.toLowerCase()?.includes(inputValue?.toLowerCase())
      );
    }
  };

  useEffect(() => {
    if (!country) {
      const currentCountry = getCountriesResult?.find((item) => item?.id === countryId)?.country;
      if (currentCountry) {
        setCountry({
          value: getCountriesResult?.find((item) => item?.id === countryId)?.country?.code,
          label: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  backgroundImage: `url(${currentCountry?.flag})`,
                  backgroundPosition: "cover",
                  backgroundRepeat: "no-repeat",
                  marginRight: "5px",
                }}
              ></div>

              <p>{currentCountry.name}</p>
            </div>
          ),
        });
      }
    }
  }, [getCountriesResult, country, countryId, setCountry]);

  return (
    <div id='CarrierSubscribeFormStep2'>
      <Title back={true} setStep={setStep} />
      <SubTitle text={t("landing.landingCarrierSubscribe.We are almost there")} />
      <ProgressBar percent={30} />
      <div>
        <Label
          text={t("landing.landingCarrierSubscribe.Company name")}
          id={"CarrierSubscribeFormStep2CompanyName"}
        />
        <Input
          id={"CarrierSubscribeFormStep2CompanyName"}
          value={companyName}
          setValue={setCompanyName}
          placeholder={t("landing.landingCarrierSubscribe.Enter company name")}
          error={subscribeStep2Error?.errors?.company_name?.[0]}
        />
        <ErrorText error={subscribeStep2Error?.errors?.company_name?.[0]} />
      </div>
      <div>
        <Label
          text={t("landing.landingCarrierSubscribe.Company ID")}
          id={"CarrierSubscribeFormStep2CompanyId"}
        />
        <Input
          id={"CarrierSubscribeFormStep2CompanyId"}
          value={companyId}
          setValue={setCompanyId}
          placeholder={t("landing.landingCarrierSubscribe.Enter company ID")}
          error={subscribeStep2Error?.errors?.company_code?.[0]}
          type={"number"}
        />
        <ErrorText error={subscribeStep2Error?.errors?.company_code?.[0]} />
      </div>
      <div>
        <Label
          text={t("landing.landingCarrierSubscribe.Home country")}
          id={"CarrierSubscribeFormStep2Country"}
        />
        <SelectForm
          id={"CarrierSubscribeFormStep2Country"}
          value={country}
          setValue={setCountry}
          options={options}
          search
          filterOption={filterOption}
          loading={getAllCountriesLoading}
          error={subscribeStep2Error?.errors?.registration_country?.[0]}
        />
        <ErrorText error={subscribeStep2Error?.errors?.registration_country?.[0]} />
      </div>

      <Footer loading={loading} submitHandler={handleSubmit} />
    </div>
  );
};

export default Step2;
