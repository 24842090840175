import React from "react";
import "./FilterProductCategorySelect.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Select from "react-select";

const FilterProductCategorySelect = ({ value, setValue }) => {
  const { t } = useTranslation();
  const { getCargoTypesSelect, getCargoTypesLoading } = useSelector(
    (state) => state.orderSettings
  );

  return (
    <div id='FilterProductCategorySelect'>
      <Select
        classNamePrefix='select'
        name='filterProductCategorySelect'
        className='selectComponent'
        options={getCargoTypesSelect || []}
        placeholder={t("shipper.filters.Product category")}
        isLoading={getCargoTypesLoading}
        value={value}
        onChange={(state) => setValue(state)}
        isClearable={true}
        isSearchable={true}
        noOptionsMessage={() => (
          <div>{t("shipper.filters.No product category found")}</div>
        )}
      />
    </div>
  );
};

export default FilterProductCategorySelect;
