import React, { useState, useEffect } from "react";
import "./PasswordVerify.scss";

import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  checkOtp,
  resetCheckOtp,
  forgotPassword,
  resetForgotPassword,
} from "./../../../../store/auth/authSlice";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import Button from "../../../Form/Button/Button";
import NotHaveAccount from "../../../Form/NotHaveAccount/NotHaveAccount";
import SendAgain from "../../Components/SendAgain/SendAgain";
import OTP from "../../../Form/OTP/OTP";

const PasswordVerify = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { phoneIndexValueId, getPhoneIndexesResult } = useSelector((state) => state.settings);
  const phoneIndexValue =
    getPhoneIndexesResult && getPhoneIndexesResult.find((item) => item.id === phoneIndexValueId);

  const {
    checkOtpLoading,
    checkOtpResult,
    checkOtpError,
    forgotPasswordResult,
    forgotPasswordLoading,
  } = useSelector((state) => state.auth);

  const inputValue = searchParams.get("value");
  const method = searchParams.get("method");
  const [value, setValue] = useState();

  useEffect(() => {
    if (checkOtpResult) {
      const url = `/forgot-password/reset?method=${method}&input-value=${inputValue}&value=${value}`;
      navigate(url);
      dispatch(resetCheckOtp());
      dispatch(resetForgotPassword());
      setValue();
    }
  }, [dispatch, navigate, checkOtpResult, value, method, inputValue]);

  useEffect(() => {
    return () => {
      dispatch(resetCheckOtp());
      dispatch(resetForgotPassword());
    };
  }, [dispatch]);

  const submitHandler = () => {
    const data =
      method === "EMAIL"
        ? { code: value, email: inputValue }
        : { code: value, phone_index_id: phoneIndexValueId, phone: inputValue };
    dispatch(checkOtp(data));
  };

  const sendAgainHandler = () => {
    const data =
      method === "EMAIL"
        ? { email: inputValue }
        : { phone_index_id: phoneIndexValueId, phone: inputValue };
    dispatch(forgotPassword(data));
  };

  return (
    <AuthFormWrapper>
      <div id='PasswordVerify' className='PasswordVerify'>
        <FormTitle title={t("landing.auth.titles.Verification code")}>
          {t("landing.auth.titles.Please enter the verification code you received on")}{" "}
          <span>
            {method === "PHONE" && phoneIndexValue && phoneIndexValue.phone_index}
            {inputValue}
          </span>
        </FormTitle>
        <OTP
          value={value}
          setValue={setValue}
          count={method === "EMAIL" ? 6 : 4}
          error={(checkOtpError?.errors?.code?.[0] ?? null) || (checkOtpError?.error ?? null)}
        />
        <Button
          loading={checkOtpLoading}
          title={t("landing.auth.form.Verified & Continue")}
          onClick={() => {
            submitHandler();
          }}
        />
        <SendAgain
          title={t("landing.auth.form.Send it again")}
          onClick={() => sendAgainHandler()}
          loading={forgotPasswordLoading}
          result={forgotPasswordResult}
          reset={() => dispatch(resetForgotPassword())}
        />
        <NotHaveAccount link='/login' text={t("landing.auth.form.Back to sign in")} />
      </div>
    </AuthFormWrapper>
  );
};

export default PasswordVerify;
