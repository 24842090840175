import React, { useEffect, useState } from "react";
import "./FilterCustomerSelect.scss";

import { useTranslation } from "react-i18next";

import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";

import { getCustomers, resetGetCustomers } from "../../../store/customer/customerSlice";

const FilterCustomerSelect = ({ value, setValue }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");

  const {
    getCustomersSelect,
    getCustomersLoading,
    createCustomerResult,
    updateCustomerResult,
    deleteCustomerResult,
  } = useSelector((state) => state.customer);

  useEffect(() => {
    return () => {
      dispatch(resetGetCustomers());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCustomers({ keyword: keyword }));
  }, [dispatch, keyword, createCustomerResult, updateCustomerResult, deleteCustomerResult]);
  return (
    <div id='FilterCustomerSelect'>
      <Select
        classNamePrefix='select'
        name='filterCustomerSelect'
        className='selectComponent'
        options={getCustomersSelect || []}
        placeholder={t("tms.filters.placeholders.Customer")}
        value={value}
        isLoading={getCustomersLoading}
        onChange={(state) => setValue(state)}
        isClearable={true}
        isSearchable={true}
        onInputChange={(state) => setKeyword(state)}
        noOptionsMessage={() => <div>{t("tms.filters.others.No customer found")}</div>}
      />
    </div>
  );
};

export default FilterCustomerSelect;
