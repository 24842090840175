import React, { Fragment } from "react";
import "./Step1.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FiCheck } from "react-icons/fi";

import SelectableButton from "../../../../../componentsDashboard/CustomersPartners/Components/SelectableButton/SelectableButton";
import Title from "../Components/Title/Title";
import SubTitle from "../Components/SubTitle/SubTitle";
import ProgressBar from "../Components/ProgressBar/ProgressBar";
import Footer from "../Components/Footer/Footer";
import ErrorText from "../../../../Form/ErrorText/ErrorText";

const Step1 = ({
  switcherValue,
  setSwitcherValue,
  radioValue,
  setRadioValue,
  selectedRoutes,
  setSelectedRoutes,
  selectedTrailerTypes,
  setSelectedTrailerTypes,
  handleSubmit,
  loading,
}) => {
  const { t } = useTranslation();
  const { getTrailerTypesRoadResult } = useSelector((state) => state.trailerType);
  const { getRoutesRoadListResult } = useSelector((state) => state.routes);
  const { subscribeStep1Error } = useSelector((state) => state.subscribe);

  const routes = getRoutesRoadListResult?.filter((route) => route?.id <= 7 || route?.id === 11);

  const radioArr = ["1", "2-5", "6-10", "11-25", "25+"];

  return (
    <div id='CarrierSubscribeFormStep1'>
      <Title />
      <SubTitle text={t("landing.landingCarrierSubscribe.Join the future")} />
      <ProgressBar percent={0} />
      <div className='formContainer'>
        <div className='mainForm'>
          <div className='switcher'>
            <div className='switcherItems'>
              <div
                className={`item ${switcherValue === "fleetOwner" && "active"}`}
                onClick={() => setSwitcherValue("fleetOwner")}
              >
                {t("landing.landingCarrierSubscribe.Fleet owner")}
              </div>
              <div
                className={`item ${switcherValue === "forwarder" && "active"}`}
                onClick={() => setSwitcherValue("forwarder")}
              >
                {t("landing.landingCarrierSubscribe.Forwarder")}
              </div>
            </div>
          </div>
          {switcherValue === "fleetOwner" && (
            <Fragment>
              <div className='label'>
                {t("landing.landingCarrierSubscribe.Choose the number of operated trucks")}
              </div>
              <div className='checkBoxList'>
                {radioArr?.map((item, index) => (
                  <div key={index} className={radioValue === item ? "active item" : "item"}>
                    <div
                      className='icon'
                      onClick={() => {
                        setRadioValue(item);
                      }}
                    >
                      <FiCheck />
                    </div>
                    <p
                      onClick={() => {
                        setRadioValue(item);
                      }}
                    >
                      {item}
                    </p>
                  </div>
                ))}
              </div>
            </Fragment>
          )}
          <div className='selectableButtonContainer'>
            <div className='label'>
              {t("landing.landingCarrierSubscribe.Choose preferred destinations")}
            </div>
            <div className='buttons'>
              {routes?.map((route) => (
                <SelectableButton
                  key={route?.id}
                  item={route}
                  selectedTypes={selectedRoutes}
                  setSelectedTypes={setSelectedRoutes}
                  selected={selectedRoutes.includes(route?.id)}
                />
              ))}
            </div>
            <ErrorText error={subscribeStep1Error?.errors?.routes?.[0]} />
          </div>
          <div className='selectableButtonContainer'>
            <div className='label'>
              {t("landing.landingCarrierSubscribe.Choose transportation types")}
            </div>
            <div className='buttons'>
              {getTrailerTypesRoadResult?.map((trailerType) => (
                <SelectableButton
                  key={trailerType?.id}
                  item={trailerType}
                  selectedTypes={selectedTrailerTypes}
                  setSelectedTypes={setSelectedTrailerTypes}
                  selected={selectedTrailerTypes.includes(trailerType?.id)}
                />
              ))}
            </div>
            <ErrorText error={subscribeStep1Error?.errors?.trailer_types?.[0]} />
          </div>
        </div>
        <Footer loading={loading} submitHandler={handleSubmit} />
      </div>
    </div>
  );
};

export default Step1;
