import _request from "./../_request";

const getBoards = async (ISO, TOKEN, branch_id, data) => {
  let url = `/load-boards/available-orders?branch_id=${branch_id}`;
  const {
    page,
    has_bid,
    status_id,
    trailer_type_id,
    start_date,
    end_date,
    has_bidding,
    max_weight,
    pickup_address,
    dropoff_address,
    sort,
    direction,
  } = data || {};

  url += page ? `&page=${page}` : "";
  url += has_bid ? `&has_bid=false` : "";
  url += status_id ? `&status_id=${status_id}` : "";
  url += trailer_type_id ? `&trailer_type_id=${trailer_type_id}` : "";
  url += start_date ? `&pickup_date[min]=${start_date}` : "";
  url += end_date ? `&pickup_date[max]=${end_date}` : "";
  url += has_bidding ? `&has_bidding=${has_bidding}` : "";
  url += max_weight ? `&max_weight=${max_weight}` : "";
  url += pickup_address ? `&pickup_address=${pickup_address}` : "";
  url += dropoff_address ? `&dropoff_address=${dropoff_address}` : "";
  url += sort ? `&sortby=${sort}` : "";
  url += direction ? `&direction=${direction}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getBoard = async (ISO, TOKEN, id) => {
  const config = {
    url: `/load-boards/available-orders/${id}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getBids = async (ISO, TOKEN, data) => {
  let url = "/load-boards/available-orders?has_my_bids=1";
  const { page } = data || {};

  url += page ? `&page=${page}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const takeBoard = async (ISO, TOKEN, id) => {
  const config = {
    url: `/load-boards/${id}/take`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const refuseBoard = async (ISO, TOKEN, id, data) => {
  // const config = {
  //   url: `order/${id}/additional-costs`,
  //   method: "post",
  //   token: TOKEN,
  //   ISO,
  // };
  // return _request(config, data);
};

const biddingBoard = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `bidding/${id}/make-tms-bid`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const loadBoardsService = {
  getBoards,
  getBoard,
  getBids,
  takeBoard,
  refuseBoard,
  biddingBoard,
};

export default loadBoardsService;
