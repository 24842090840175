import React from "react";
import "./PaymentDeadlineTimeField.scss";

import { useTranslation } from "react-i18next";
import moment from "moment";

const PaymentDeadlineTimeField = ({ order }) => {
  const { t } = useTranslation();

  const paymentDeadline = order?.payment_deadline; // Your payment deadline date
  const today = moment(); // Current date
  const daysDifference = moment(paymentDeadline).diff(today, "days");

  const paymentDeadlineColor =
    order?.payment_deadline !== null && daysDifference < 0
      ? "#DA1414"
      : daysDifference < 3
      ? "#B95000"
      : "#2B353A";

  return (
    <td id='PaymentDeadlineTimeField'>
      <div className='container'>
        {order?.payment_status === "paid" ? (
          <p>{t("shipper.table.desc.No deadline")}</p>
        ) : (
          <p style={{ color: paymentDeadlineColor }}>
            {daysDifference} {t("shipper.units.days")}
          </p>
        )}
      </div>
    </td>
  );
};

export default PaymentDeadlineTimeField;
