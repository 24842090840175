import React, { useState, useEffect } from "react";
import "./FavoriteRoutesModal.scss";

import { useTranslation } from "react-i18next";
import { MdEdit, MdClose } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

import {
  getLocations,
  resetGetLocations,
} from "../../../../store/favoriteLocations/favoriteLocationsSlice";

import DOverlay from "./../../../Modal/DOverlay/DOverlay";
import FavoriteRoutesModalItem from "./FavoriteRoutesModalItem/FavoriteRoutesModalItem";
import LoadingPage from "./../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";

const FavoriteRoutesModal = ({
  isOpen,
  isClose,
  setPickUpInput,
  setDropOffInput,
  setPickUp,
  setDropOff,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getLocationsLoading, getLocationsResult, createLocationResult, deleteLocationResult } =
    useSelector((state) => state.favoriteLocations);

  const [isEditActive, setIsEditActive] = useState(false);
  const toggleEditActive = () => {
    setIsEditActive(!isEditActive);
  };

  useEffect(() => {
    dispatch(getLocations());
    return () => {
      dispatch(resetGetLocations());
    };
  }, [dispatch, createLocationResult, deleteLocationResult]);

  return (
    <DOverlay isOpen={isOpen} isClose={isClose} transparent>
      <div id='FavoriteRoutesModal'>
        <div className='FavoriteRoutesModalContainer'>
          <div className='FavoriteRoutesModalContainerHeader'>
            <div className='FavoriteRoutesModalContainerHeaderLeft'>
              <h1>{t("tms.titles.Favourite routes")}</h1>
            </div>
            <div className='FavoriteRoutesModalContainerHeaderRight'>
              <div
                className={
                  isEditActive ? "FavoriteRoutesModalIcon active" : "FavoriteRoutesModalIcon"
                }
                onClick={toggleEditActive}
              >
                <MdEdit />
              </div>
              <div className='FavoriteRoutesModalIcon' onClick={isClose}>
                <MdClose />
              </div>
            </div>
          </div>
          {getLocationsLoading && <LoadingPage />}
          {getLocationsResult && !getLocationsLoading && (
            <ul className='FavoriteRoutesModalContainerContent'>
              {getLocationsResult.length === 0 ? (
                <div id='noRoutes'>{t("tms.desc.No favourite routes found.")}</div>
              ) : (
                getLocationsResult.map((item) => (
                  <FavoriteRoutesModalItem
                    key={item.id}
                    isEditActive={isEditActive}
                    item={item}
                    isClose={isClose}
                    setPickUpInput={setPickUpInput}
                    setDropOffInput={setDropOffInput}
                    setPickUp={setPickUp}
                    setDropOff={setDropOff}
                  />
                ))
              )}
            </ul>
          )}
        </div>
      </div>
    </DOverlay>
  );
};

export default FavoriteRoutesModal;
