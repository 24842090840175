import React, { useEffect } from "react";
import "./RoadLocation.scss";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  getDropoffLOCALITY,
  getPickupLOCALITY,
  resetGetDropoffLOCALITY,
  resetGetPickupLOCALITY,
} from "../../../../../store/locationSuggestion/locationSuggestionSlice";

import CreateOrderSectionTitle from "./../../Components/CreateOrderSectionTitle/CreateOrderSectionTitle";
import RoadLocationRoute from "./RoadLocationRoute/RoadLocationRoute";
import RoadLocationTime from "./RoadLocationTime/RoadLocationTime";

const RoadLocation = ({
  edit,
  ////
  locations,
  setLocations,
  ////
  pickUpDate,
  setPickUpDate,
  pickUpDateError,
  ////
  showPickUpTime,
  setShowPickUpTime,
  pickUpTime,
  setPickUpTime,
  pickUpTimeError,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getPickupLOCALITY(user?.client_id));
    dispatch(getDropoffLOCALITY(user?.client_id));
    return () => {
      dispatch(resetGetPickupLOCALITY());
      dispatch(resetGetDropoffLOCALITY());
    };
  }, [dispatch, user?.client_id]);

  return (
    <div id='RoadLocationShipper'>
      <CreateOrderSectionTitle title={t("shipper.forms.titles.Route")} />
      <div className='RoadLocationShipperWrapper'>
        <RoadLocationRoute edit={edit} locations={locations} setLocations={setLocations} />

        <RoadLocationTime
          pickUpDate={pickUpDate}
          setPickUpDate={setPickUpDate}
          pickUpDateError={pickUpDateError}
          /////
          showPickUpTime={showPickUpTime}
          setShowPickUpTime={setShowPickUpTime}
          pickUpTime={pickUpTime}
          setPickUpTime={setPickUpTime}
          pickUpTimeError={pickUpTimeError}
        />
      </div>
    </div>
  );
};

export default RoadLocation;
