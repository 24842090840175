import React from "react";
import "./FilterTrailerSelect.scss";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import Select from "react-select";

const FilterTrailerSelect = ({ value, setValue }) => {
  const { t } = useTranslation();

  const { getTrailerTypesRoadSelectResult } = useSelector((state) => state.trailerType);

  return (
    <div id='TrailerSelect'>
      <Select
        classNamePrefix='select'
        name='filterTrailerSelect'
        className='selectComponent'
        options={getTrailerTypesRoadSelectResult || []}
        placeholder={t("tms.filters.placeholders.Trailer")}
        value={value}
        onChange={(state) => setValue(state)}
        isClearable={true}
        isSearchable={true}
        noOptionsMessage={() => <div>{t("tms.filters.others.No trailer found")}</div>}
      />
    </div>
  );
};

export default FilterTrailerSelect;
