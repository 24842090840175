import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import subscribeService from "./subscribeService";

const initialState = {
  subscribeStep1Result: null,
  subscribeStep1Loading: false,
  subscribeStep1Error: null,

  subscribeStep2Result: null,
  subscribeStep2Error: null,
  subscribeStep2Loading: false,

  subscribeStep3Result: null,
  subscribeStep3Loading: false,
  subscribeStep3Error: null,

  verifyCodeResult: null,
  verifyCodeLoading: false,
  verifyCodeError: null,

  sendOtpResult: null,
  sendOtpLoading: false,
  sendOtpError: null,
};

export const subscribeStep1 = createAsyncThunk(
  "subscribe/subscribeStep1",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const response = await subscribeService.subscribeStep1(ISO, data);
      return response;
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const subscribeStep2 = createAsyncThunk(
  "subscribe/subscribeStep2",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const response = await subscribeService.subscribeStep2(ISO, data);
      return response;
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const subscribeStep3 = createAsyncThunk(
  "subscribe/subscribeStep3",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const response = await subscribeService.subscribeStep3(ISO, data);
      return response;
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyCode = createAsyncThunk("subscribe/verifyCode", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const response = await subscribeService.verifyCode(ISO, data);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const sendOtp = createAsyncThunk("subscribe/sendOtp", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const response = await subscribeService.sendOtp(ISO, data);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const subscribeSlice = createSlice({
  name: "subscribe",
  initialState,
  reducers: {
    resetSubscribeStep1: (state) => {
      state.subscribeStep1Loading = false;
      state.subscribeStep1Result = null;
      state.subscribeStep1Error = null;
    },
    resetSubscribeStep2: (state) => {
      state.subscribeStep2Loading = false;
      state.subscribeStep2Result = null;
      state.subscribeStep2Error = null;
    },
    resetSubscribeStep3: (state) => {
      state.subscribeStep3Loading = false;
      state.subscribeStep3Result = null;
      state.subscribeStep3Error = null;
    },
    resetVerifyCode: (state) => {
      state.verifyCodeLoading = false;
      state.verifyCodeResult = null;
      state.verifyCodeError = null;
    },
    resetSendOtp: (state) => {
      state.sendOtpLoading = false;
      state.sendOtpResult = null;
      state.sendOtpError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(subscribeStep1.pending, (state) => {
        state.subscribeStep1Loading = true;
        state.subscribeStep1Result = null;
        state.subscribeStep1Error = null;
      })
      .addCase(subscribeStep1.rejected, (state, action) => {
        state.subscribeStep1Loading = false;
        state.subscribeStep1Error = action.payload;
        if (
          action.payload?.errors &&
          !action.payload?.errors?.trailer_types &&
          !action.payload?.errors?.routes &&
          !action.payload?.errors?.fleet_owner &&
          !action.payload?.errors?.fleet_quantity
        ) {
          state.subscribeStep1Result = "First step completed successfully!";
        } else {
          state.subscribeStep1Result = null;
        }
      })

      .addCase(subscribeStep2.pending, (state) => {
        state.subscribeStep2Loading = true;
        state.subscribeStep2Result = null;
        state.subscribeStep2Error = null;
      })
      .addCase(subscribeStep2.rejected, (state, action) => {
        state.subscribeStep2Loading = false;
        state.subscribeStep2Error = action.payload;
        if (
          action.payload?.errors &&
          !action.payload?.errors?.registration_country &&
          !action.payload?.errors?.company_name &&
          !action.payload?.errors?.company_code
        ) {
          state.subscribeStep1Result = "Second step completed successfully!";
        } else {
          state.subscribeStep1Result = null;
        }
      })

      .addCase(subscribeStep3.pending, (state) => {
        state.subscribeStep3Loading = true;
        state.subscribeStep3Result = null;
        state.subscribeStep3Error = null;
      })
      .addCase(subscribeStep3.fulfilled, (state, action) => {
        state.subscribeStep3Loading = false;
        state.subscribeStep3Result = action.payload;
        state.subscribeStep3Error = null;
      })
      .addCase(subscribeStep3.rejected, (state, action) => {
        state.subscribeStep3Loading = false;
        state.subscribeStep3Result = null;
        state.subscribeStep3Error = action.payload;
      })

      .addCase(verifyCode.pending, (state) => {
        state.verifyCodeLoading = true;
        state.verifyCodeResult = null;
        state.verifyCodeError = null;
      })
      .addCase(verifyCode.fulfilled, (state, action) => {
        state.verifyCodeLoading = false;
        state.verifyCodeResult = action.payload;
        state.verifyCodeError = null;
      })
      .addCase(verifyCode.rejected, (state, action) => {
        state.verifyCodeLoading = false;
        state.verifyCodeResult = null;
        state.verifyCodeError = action.payload;
      })

      .addCase(sendOtp.pending, (state) => {
        state.sendOtpLoading = true;
        state.sendOtpResult = null;
        state.sendOtpError = null;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.sendOtpLoading = false;
        state.sendOtpResult = action.payload;
        state.sendOtpError = null;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.sendOtpLoading = false;
        state.sendOtpResult = null;
        state.sendOtpError = action.payload;
      });
  },
});

export const {
  resetSubscribeStep1,
  resetSubscribeStep2,
  resetSubscribeStep3,
  resetVerifyCode,
  resetSendOtp,
} = subscribeSlice.actions;

export default subscribeSlice.reducer;
