import React, { useEffect, useState } from "react";
import "./Charts.scss";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Skeleton } from "@mui/material";

import moment from "moment";
import "moment/locale/ka";
import "moment/locale/en-gb";

import { getChartAnalytics } from "../../../store/analytics/analyticsSlice";

import RevenueChart from "./RevenueChart/RevenueChart";
import ProfitRatioChart from "./ProfitRatioChart/ProfitRatioChart";
import DDropdown from "../../FilterForms/DDropdown/DDropdown";

const Charts = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { getChartAnalyticsResult, getChartAnalyticsLoading } = useSelector(
    (state) => state.analytics
  );

  const { ISO } = useSelector((state) => state.lang);
  moment.locale(ISO);

  const [activeChart, setActiveChart] = useState("revenue");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [date, setDate] = useState(new Date());

  const revenueDataWithMonths = getChartAnalyticsResult?.map(
    (month) => {
      const monthStr = Object.keys(month)?.[0];
      const data = month?.[monthStr];
      return {
        name: moment(monthStr, "YYYY-MM").format("MMM"),
        revenue: data?.revenue,
        profit: data?.profit,
        profitRatio: parseFloat(data?.profit_ratio || 0).toFixed(2),
        revenueProgress: parseFloat(
          data?.revenue_increase_percent || 0
        ).toFixed(2),
      };
    }
  );

  const profitDataWithMonths = getChartAnalyticsResult?.map((month) => {
    const monthStr = Object.keys(month)?.[0];
    const data = month?.[monthStr];
    return {
      name: moment(monthStr, "YYYY-MM").format("MMM"),
      profitRatio: data?.profit_ratio,
    };
  });

  useEffect(() => {
    dispatch(getChartAnalytics(moment(date).format("YYYY-MM")));
  }, [dispatch, date]);

  return (
    <div id='Charts'>
      <div className='chartsHeader'>
        <div className='chartsSwitcher'>
          <div
            className={`chartsSwitcherItem ${
              activeChart === "revenue" ? "active" : ""
            } `}
            onClick={() => setActiveChart("revenue")}
          >
            {t("tms.menu.Revenue")}
          </div>
          <div
            className={`chartsSwitcherItem ${
              activeChart === "profit" ? "active" : ""
            }`}
            onClick={() => setActiveChart("profit")}
          >
            {t("tms.menu.Profit ratio")}
          </div>
        </div>
        <DDropdown
          dropdownOpen={dropdownOpen}
          setDropdownOpen={setDropdownOpen}
          placeholder={moment(date).format("MMMM")}
        >
          <Calendar
            className='calendar'
            onChange={(date) => setDate(date)}
            value={date}
            minDetail='year'
            maxDetail='year'
            formatMonth={(_, date) => moment(date).format("MMM")}
            tileDisabled={({ date, view }) =>
              view === "year" &&
              (date.getFullYear() > new Date().getFullYear() ||
                (date.getFullYear() === new Date().getFullYear() &&
                  date.getMonth() > new Date().getMonth()))
            }
            onClickMonth={() => setDropdownOpen(false)}
          />
        </DDropdown>
      </div>

      {getChartAnalyticsLoading ? (
        <Skeleton variant='rounded' className='skeleton' />
      ) : activeChart === "revenue" ? (
        <RevenueChart data={revenueDataWithMonths} />
      ) : (
        <ProfitRatioChart data={profitDataWithMonths} />
      )}
    </div>
  );
};

export default Charts;
