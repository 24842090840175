import _request from "./../_request";

// login
const login = async (ISO, data) => {
  const config = {
    url: "/login",
    method: "post",
    ISO,
  };
  return _request(config, data);
};

// register
const signup = async (ISO, data) => {
  const config = {
    url: "/signup",
    method: "post",
    ISO,
  };
  return _request(config, data);
};

// verify user
const verify = async (ISO, token, data) => {
  const config = {
    url: `/verify`,
    method: "post",
    ISO,
    token,
  };
  return _request(config, data);
};

// logout
const logout = async (ISO, token) => {
  const config = {
    url: "/logout",
    method: "post",
    ISO,
    token,
  };
  return _request(config);
};

// get user
const getUser = async (ISO, token) => {
  const config = {
    url: "/profile",
    ISO,
    token,
  };
  return _request(config);
};

// re sendOtp
const sendOtp = async (ISO, token, data) => {
  const config = {
    url: `/send-otp`,
    method: "post",
    ISO,
    token,
  };
  return _request(config, data);
};

// forgotPassword
const forgotPassword = async (ISO, data) => {
  const config = {
    url: "/forgot-password",
    method: "post",
    ISO,
  };
  return _request(config, data);
};

//checkOtp
const checkOtp = async (ISO, data) => {
  const config = {
    url: "/check-otp",
    method: "post",
    ISO,
  };
  return _request(config, data);
};

// reset password
const resetPassword = async (ISO, data) => {
  const config = {
    url: "/reset-password",
    method: "post",
    ISO,
  };
  return _request(config, data);
};

const authService = {
  login,
  signup,
  logout,
  verify,
  sendOtp,
  getUser,
  forgotPassword,
  checkOtp,
  resetPassword,
};

export default authService;
