import React, { useEffect } from "react";
import "./SideDetails.scss";

import { useSelector, useDispatch } from "react-redux";

import {
  getShipperAcceptedOrder,
  resetGetShipperAcceptedOrder,
} from "./../../../store/orders/shipperAccepted/shipperAcceptedSlice";

import LoadingPage from "./../../../componentsAdditional/Loading/LoadingPage/LoadingPage";

import DetailsHeader from "../Components/DetailsHeader/DetailsHeader";
import OrderStatusBarWithoutAction from "../../Orders/OrderStatusBar/OrderStatusBarWithoutAction/OrderStatusBarWithoutAction";
import OrderDetailsWrapper from "../../Orders/OrderDetailsWrapper/OrderDetailsWrapper";
import FinancialDetailsShipper from "../../Orders/FinancialDetailsShipper/FinancialDetailsShipper";
import ShipperTracking from "../../Orders/ShipperTracking/ShipperTracking";
import DocumentsDetails from "../../Orders/DocumentsDetails/DocumentsDetails";

const SideDetails = ({ activeOrderId, setActiveOrderId }) => {
  const dispatch = useDispatch();

  const { getShipperAcceptedOrderLoading, getShipperAcceptedOrderResult } = useSelector(
    (state) => state.shipperAccepted
  );
  const { createAttachmentResult, deleteAttachmentResult } = useSelector(
    (state) => state.attachments
  );

  useEffect(() => {
    if (activeOrderId) {
      dispatch(getShipperAcceptedOrder(activeOrderId));
    }
    return () => {
      dispatch(resetGetShipperAcceptedOrder());
    };
  }, [dispatch, activeOrderId, createAttachmentResult, deleteAttachmentResult]);

  return (
    <div id='SideViewDetails'>
      {getShipperAcceptedOrderLoading && <LoadingPage />}

      {getShipperAcceptedOrderResult && (
        <div className='SideViewDetailsWrapper'>
          <DetailsHeader order={getShipperAcceptedOrderResult} />
          <OrderStatusBarWithoutAction order={getShipperAcceptedOrderResult} />
          <OrderDetailsWrapper order={getShipperAcceptedOrderResult} />

          <div className='SideViewDetailsWrapperCards'>
            <div className='SideViewDetailsWrapperCardsleft'>
              <FinancialDetailsShipper order={getShipperAcceptedOrderResult} />

              <DocumentsDetails
                orderId={getShipperAcceptedOrderResult.id}
                order={getShipperAcceptedOrderResult}
              />
            </div>

            <ShipperTracking order={getShipperAcceptedOrderResult} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SideDetails;
