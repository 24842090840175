import React, { Fragment, useState } from "react";
import "./BoardDetailAction.scss";

import { useTranslation } from "react-i18next";
import { PiWarningCircleFill } from "react-icons/pi";
import { FaCheckCircle } from "react-icons/fa";

import { useMoney } from "../../../helper/useMoney";

import DButton from "../../Form/DButtons/DButton/DButton";
import TakeOrderModal from "../Modals/TakeOrderModal/TakeOrderModal";
import MakeBidModal from "../Modals/MakeBidModal/MakeBidModal";
import DWarning from "./../../Form/DWarning/DWarning";

const BoardDetailAction = ({ order }) => {
  const { t } = useTranslation();

  const [openBidModal, setOpenBidModal] = useState(false);
  const [openTakeOrder, setOpenTakeOrder] = useState(false);

  const bidMoney = useMoney(
    order?.bid?.amount || 0,
    order?.bid?.currency?.symbol || ""
  );

  return (
    <Fragment>
      {order && (
        <div id='BoardDetailAction'>
          {order.bid ? (
            order?.takeable ? (
              <div className='TakeBidInformation'>
                <div className='icon'>
                  <FaCheckCircle />
                </div>
                <h4>{`${t(
                  "tms.desc.Take an order with a price"
                )} ${bidMoney}`}</h4>
              </div>
            ) : (
              <div className='newBidInformation'>
                <div className='icon'>
                  <PiWarningCircleFill />
                </div>
                <h4>{`${t("tms.desc.You already offer")} ${bidMoney} ${t(
                  "tms.desc.for this order"
                )}.`}</h4>
              </div>
            )
          ) : (
            <div className='Noinformation'>
              <h3>{t("tms.desc.Bidding Available")}</h3>
              <DWarning
                icon={<PiWarningCircleFill />}
                text={t(
                  "tms.desc.You can offer us your acceptable price for this order"
                )}
              />
            </div>
          )}

          <div className='action'>
            {order.bid ? (
              order?.takeable ? (
                <DButton
                  title={t("tms.tables.buttons.Take order")}
                  onClick={() => setOpenTakeOrder(true)}
                />
              ) : (
                <div className='newBid'>
                  <DButton
                    title={t("tms.tables.buttons.New bid")}
                    onClick={() => setOpenBidModal(true)}
                  />
                </div>
              )
            ) : (
              <DButton
                title={t("tms.tables.buttons.Make bid")}
                onClick={() => setOpenBidModal(true)}
              />
            )}
          </div>
        </div>
      )}
      {openBidModal && (
        <MakeBidModal
          item={order}
          isOpen={openBidModal}
          isClose={() => setOpenBidModal(false)}
        />
      )}
      {openTakeOrder && (
        <TakeOrderModal
          item={order}
          isOpen={openTakeOrder}
          isClose={() => setOpenTakeOrder(false)}
        />
      )}
    </Fragment>
  );
};

export default BoardDetailAction;
