import React from "react";
import "./LoadBoardFilters.scss";

import moment from "moment";
import { useTranslation } from "react-i18next";

import LoadBoardAddressSearchForm from "../LoadBoardAddressSearchForm/LoadBoardAddressSearchForm";
import FilterTrailerSelect from "../../FilterForms/FilterTrailerSelect/FilterTrailerSelect";
import FilterMaxWeightSelect from "../../FilterForms/FilterMaxWeightSelect/FilterMaxWeightSelect";
import FiltersContainer from "../../FilterForms/FiltersContainer/FiltersContainer";
import FilterDateRangeForm from "../../FilterForms/FilterDateRangeForm/FilterDateRangeForm";

const LoadBoardFilters = ({
  setPickUp,
  setDropOff,
  trailerType,
  setTrailerType,
  maxWeight,
  setMaxWeight,
  date,
  setDate,
  clearFilters,
  pickUpInput,
  setPickUpInput,
  dropOffInput,
  setDropOffInput,
}) => {
  const { t } = useTranslation();

  return (
    <div id='LoadBoardFilters'>
      <LoadBoardAddressSearchForm
        setPickUp={setPickUp}
        setDropOff={setDropOff}
        pickUpInput={pickUpInput}
        setPickUpInput={setPickUpInput}
        dropOffInput={dropOffInput}
        setDropOffInput={setDropOffInput}
      />
      <FiltersContainer onClear={clearFilters}>
        <FilterDateRangeForm
          label={t("tms.filters.placeholders.Date range")}
          value={
            Object.keys(date).length > 0
              ? `${
                  date.startDate
                    ? moment(date.startDate).format("DD/MM/YYYY")
                    : ""
                } - ${
                  date.endDate ? moment(date.endDate).format("DD/MM/YYYY") : ""
                }`
              : ""
          }
          setValue={setDate}
        />
        <FilterTrailerSelect value={trailerType} setValue={setTrailerType} />
        <FilterMaxWeightSelect
          setMaxWeight={setMaxWeight}
          maxWeight={maxWeight}
        />
      </FiltersContainer>
    </div>
  );
};

export default LoadBoardFilters;
