import React, { useEffect, useState } from "react";
import "./Step4.scss";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  resetSendOtp,
  resetVerifyCode,
  sendOtp,
  verifyCode,
} from "../../../../../store/subscribe/subscribeSlice";

import Button from "../../../../Form/Button/Button";
import OTP from "../../../../Form/OTP/OTP";
import SendAgain from "../../../../Auth&Password/Components/SendAgain/SendAgain";

const Step4 = ({ setStep, method, phone, phoneIndexId, email }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { verifyCodeResult, verifyCodeError, verifyCodeLoading, sendOtpLoading, sendOtpResult } =
    useSelector((state) => state.subscribe);
  const [otp, setOtp] = useState();

  const submitHandler = () => {
    const data =
      method === "email"
        ? { code: otp, email: email }
        : { code: otp, phone_index_id: phoneIndexId, phone: phone };
    dispatch(verifyCode(data));
  };

  const handleResend = () => {
    const data =
      method === "email"
        ? { email: email, contact_channel: "email" }
        : { phone_index_id: phoneIndexId, phone: phone, contact_channel: "phone" };
    dispatch(sendOtp(data));
  };

  useEffect(() => {
    if (verifyCodeResult) {
      setStep((prev) => prev + 1);
      navigate("/carrier/subscribe?success");
    }
    return () => {
      dispatch(resetVerifyCode());
    };
  }, [verifyCodeResult, dispatch, setStep, navigate]);

  return (
    <div id='CarrierSubscribeFormStep4'>
      <div className='titleContainer'>
        <div className='title'>{t("landing.landingCarrierSubscribe.Verification")}</div>
        <div className='subTitle'>
          {t(
            "landing.landingCarrierSubscribe.Please enter verification code which you recieved on your {method}.",
            {
              method:
                method === "phone"
                  ? t("landing.landingCarrierSubscribe.phone")
                  : t("landing.landingCarrierSubscribe.email"),
            }
          )}
        </div>
      </div>
      <div className='otpContainer'>
        <OTP
          value={otp}
          setValue={setOtp}
          count={method === "phone" ? 4 : 6}
          error={verifyCodeError?.errors?.code?.[0] || verifyCodeError?.error}
        />
        <Button
          title={t("landing.landingCarrierSubscribe.Subscribe")}
          onClick={submitHandler}
          loading={verifyCodeLoading}
        />
      </div>
      <div className='secondaryButtonsContainer'>
        <SendAgain
          title={t("landing.landingCarrierSubscribe.Resend")}
          onClick={handleResend}
          loading={sendOtpLoading}
          result={sendOtpResult}
          reset={() => dispatch(resetSendOtp())}
        />
        <div className='changePhoneButton' onClick={() => setStep(3)}>
          {t("landing.landingCarrierSubscribe.Change the {method}", {
            method:
              method === "phone"
                ? t("landing.landingCarrierSubscribe.phone")
                : t("landing.landingCarrierSubscribe.email"),
          })}
        </div>
      </div>
    </div>
  );
};

export default Step4;
