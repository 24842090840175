import React, { useState, useEffect } from "react";
import "./CreateRoadOrder.scss";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { createOrder, resetCreateOrder } from "../../../../../../store/orders/ordersSlice";

import Wrapper from "./../../../../../../componentsAdditional/Wrapper";
import CreateOrderHeader from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderHeader/CreateOrderHeader";
import RoadTypeSwitcher from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadTypeSwitcher/RoadTypeSwitcher";
import RoadTopMenu from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadTopMenu/RoadTopMenu";
import CreateOrderAditionalServices from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalServices/CreateOrderAditionalServices";
import CreateOrderAditionalInformation from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalInformation/CreateOrderAditionalInformation";
import RoadLocation from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadLocation/RoadLocation";
import RoadPackage from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperRoad/RoadPackage/RoadPackage";
import CreateOrderSubmit from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderSubmit/CreateOrderSubmit";

const CreateRoadOrder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { createOrderLoading, createOrderResult, createOrderError } = useSelector(
    (state) => state.orders
  );

  const ORDERTYPES = [
    { id: 2, name: "FTL" },
    { id: 1, name: "LTL" },
  ];

  const [orderTypeId, setOrderTypeId] = useState(2);
  //// Route
  const [locations, setLocations] = useState([
    {
      direction: "A",
      inputted_address: "",
      error: null,
    },
    {
      direction: "B",
      inputted_address: "",
      error: null,
    },
  ]);
  const [pickUpDate, setPickUpDate] = useState(null);
  const [showPickUpTime, setShowPickUpTime] = useState(false);
  const [pickUpTime, setPickUpTime] = useState(null);
  const [totalWeight, setTotalWeight] = useState("");
  const [cargoType, setCargoType] = useState(null);
  const [packageType, setPackageType] = useState(null);
  const [HSCode, setHSCode] = useState(false);
  const [HSCodeValue, setHSCodeValue] = useState("");
  const [adr, setAdr] = useState(false);
  const [adrNumber, setAdrNumber] = useState("");
  const [adrClass, setAdrClass] = useState("");
  const [adrFile, setAdrFile] = useState([]);
  const [oversized, setOversized] = useState(false);
  const [oversizedFile, setOversizedFile] = useState([]);
  const [oversizedCall, setOversizedCall] = useState(false);
  const [group, setGroup] = useState([
    {
      unique: 1,
      trailerType: null,
      trailerTypeError: null,
      trailerSize: null,
      trailerSizeError: null,
      temperature: "",
      temperatureError: null,
      weight: "",
      weightError: null,
    },
  ]);
  ///// Additional Services
  const [selectedServices, setSelectedServices] = useState([]);
  ///// Additional information
  const [cargoPrice, setCargoPrice] = useState("");
  const [cargoPriceCurrency, setCargoPriceCurrency] = useState(
    user
      ? {
          label: user?.currency?.code || "",
          value: user?.currency || null,
        }
      : null
  );
  const [readyCargoTime, setReadyCargoTime] = useState(null);
  const [comment, setComment] = useState("");
  const [aditionalDocument, setAditionalDocument] = useState([]);

  /// weight divider
  useEffect(() => {
    if (totalWeight && totalWeight.length > 0) {
      const newGroup = group.map((i) => ({
        ...i,
        weight: parseFloat((+totalWeight / group.length).toFixed(0)) || "",
      }));
      setGroup(newGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalWeight, group.length]);

  // catch locations error
  useEffect(() => {
    if (createOrderError?.errors) {
      const updatedLocations = locations.map((location, index) => ({
        ...location,
        error:
          createOrderError.errors[`0.cargos.0.locations.${index}.inputted_address`]?.[0] ?? null,
      }));
      setLocations(updatedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderError]);

  // catch group errors
  useEffect(() => {
    if (createOrderError?.errors) {
      const updatedGroup = group.map((item, index) => ({
        ...item,
        trailerTypeError:
          createOrderError?.errors?.[`${index}.cargos.0.trailer_type_id`]?.[0] || null,
        trailerSizeError:
          createOrderError?.errors?.[`${index}.cargos.0.trailer_size_id`]?.[0] || null,
        temperatureError: createOrderError?.errors?.[`${index}.cargos.0.temperature`]?.[0] || null,
        weightError: createOrderError?.errors?.[`${index}.cargos.0.weight`]?.[0] || null,
      }));
      setGroup(updatedGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderError]);

  // reset
  useEffect(() => {
    if (createOrderResult) {
      dispatch(resetCreateOrder());
      navigate("/dashboard/shipper/orders?page=1");
    }
  }, [dispatch, navigate, createOrderResult]);

  // reset all time
  useEffect(() => {
    return () => {
      dispatch(resetCreateOrder());
    };
  }, [dispatch]);

  // submit
  const submitHandler = () => {
    const additionalFilesIds = aditionalDocument.map((doc) => doc.id);
    const adrFilesIds = adrFile.map((doc) => doc.id);
    const oversizedFileIds = oversizedFile.map((doc) => doc.id);
    let documents = [...additionalFilesIds, ...adrFilesIds, ...oversizedFileIds];

    const data = group.map((item) => ({
      priority_id: 1,
      transfer_type_id: 1,
      type_id: orderTypeId || null,
      revenue: 0,
      revenue_currency_id: user?.currency?.id || null,
      client_payment: null,
      about: comment || null,
      services: selectedServices.map((i) => i.id),
      documents: documents,
      cargos: [
        {
          trailer_type_id: item?.trailerType?.value?.id || null,
          trailer_size_id: item?.trailerSize?.value?.id || null,
          temperature: item?.temperature || null,
          type_id: cargoType?.value?.id || null,
          count: 1,
          weight: item?.weight || null,
          volume: null,
          packing_type_id: packageType?.value?.id || null,
          adr: adr || false,
          adr_class: adrClass || null,
          un_number: adrNumber || null,
          hs_code: HSCodeValue || null,
          oversized: oversized || false,
          oversized_call: oversizedCall ? true : null,
          price: cargoPrice || null,
          price_currency_id: cargoPriceCurrency?.value?.id || null,
          cargo_ready_date: readyCargoTime ? moment(readyCargoTime).format("YYYY-MM-DD") : null,
          pickup_date: pickUpDate ? moment(pickUpDate).format("YYYY-MM-DD") : null,
          pickup_time_start: pickUpTime ? pickUpTime[0] : null,
          pickup_time_end: pickUpTime ? pickUpTime[1] : null,
          dropoff_date: null,
          dropoff_time_start: null,
          dropoff_time_end: null,
          locations: locations.map((loc) => ({
            direction: loc?.direction || null,
            type: "LOCALITY",
            inputted_address: loc?.inputted_address || null,
          })),
        },
      ],
    }));
    dispatch(createOrder(data));
  };

  return (
    <Wrapper documentTitle={t("shipper.titles.Road freight")}>
      <RoadTopMenu
        ORDERTYPES={ORDERTYPES}
        orderTypeId={orderTypeId}
        pickUpDate={pickUpDate}
        totalWeight={totalWeight}
        cargoType={cargoType}
        group={group}
        locations={locations}
      />
      <section id='ShipperCreateOrderRoad'>
        <div className='ShipperCreateOrderRoadWrapper'>
          <CreateOrderHeader />
          <RoadTypeSwitcher
            ORDERTYPES={ORDERTYPES}
            orderTypeId={orderTypeId}
            setOrderTypeId={setOrderTypeId}
          />
          <RoadLocation
            edit={false}
            locations={locations}
            setLocations={setLocations}
            /////
            pickUpDate={pickUpDate}
            setPickUpDate={setPickUpDate}
            pickUpDateError={createOrderError?.errors?.["0.cargos.0.pickup_date"]?.[0] ?? null}
            /////
            showPickUpTime={showPickUpTime}
            setShowPickUpTime={setShowPickUpTime}
            pickUpTime={pickUpTime}
            setPickUpTime={setPickUpTime}
            pickUpTimeError={
              (createOrderError?.errors?.["0.cargos.0.pickup_time_start"]?.[0] ?? null) ||
              (createOrderError?.errors?.["0.cargos.0.pickup_time_end"]?.[0] ?? null)
            }
          />
          <RoadPackage
            edit={false}
            totalWeight={totalWeight}
            setTotalWeight={setTotalWeight}
            totalWeightError={createOrderError?.errors?.[`0.cargos.0.weight`]?.[0] || null}
            //////
            cargoType={cargoType}
            setCargoType={setCargoType}
            cargoTypeError={createOrderError?.errors?.["0.cargos.0.type_id"]?.[0] ?? null}
            packageType={packageType}
            setPackageType={setPackageType}
            packageTypeError={createOrderError?.errors?.["0.cargos.0.packing_type_id"]?.[0] ?? null}
            HSCode={HSCode}
            setHSCode={setHSCode}
            HSCodeValue={HSCodeValue}
            setHSCodeValue={setHSCodeValue}
            HSCodeValueError={createOrderError?.errors?.["0.cargos.0.hs_code"]?.[0] ?? null}
            //////
            adr={adr}
            setAdr={setAdr}
            adrError={createOrderError?.errors?.["0.cargos.0.adr"]?.[0] ?? null}
            adrNumber={adrNumber}
            setAdrNumber={setAdrNumber}
            adrNumberError={createOrderError?.errors?.["0.cargos.0.un_number"]?.[0] ?? null}
            adrClass={adrClass}
            setAdrClass={setAdrClass}
            adrClassError={createOrderError?.errors?.["0.cargos.0.adr_class"]?.[0] ?? null}
            adrFile={adrFile}
            setAdrFile={setAdrFile}
            //////
            oversized={oversized}
            setOversized={setOversized}
            oversizedError={createOrderError?.errors?.["0.cargos.0.oversized"]?.[0] ?? null}
            oversizedFile={oversizedFile}
            setOversizedFile={setOversizedFile}
            oversizedCall={oversizedCall}
            setOversizedCall={setOversizedCall}
            //////
            group={group}
            setGroup={setGroup}
          />
          <CreateOrderAditionalServices
            title={t("shipper.forms.titles.Additional Services")}
            selectedServices={selectedServices}
            setSelectedServices={setSelectedServices}
          />
          <CreateOrderAditionalInformation
            title={t("shipper.forms.titles.Additional information")}
            /////
            readyCargoTime={readyCargoTime}
            setReadyCargoTime={setReadyCargoTime}
            readyCargoTimeError={
              createOrderError?.errors?.["0.cargos.0.cargo_ready_date"]?.[0] ?? null
            }
            /////
            cargoPrice={cargoPrice}
            setCargoPrice={setCargoPrice}
            cargoPriceError={createOrderError?.errors?.["0.cargos.0.price"]?.[0] ?? null}
            /////
            cargoPriceCurrency={cargoPriceCurrency}
            setCargoPriceCurrency={setCargoPriceCurrency}
            cargoPriceCurrencyError={
              createOrderError?.errors?.["0.cargos.0.price_currency_id"]?.[0] ?? null
            }
            //////
            comment={comment}
            setComment={setComment}
            commentError={createOrderError?.errors?.["0.about"]?.[0] ?? null}
            //////
            aditionalDocument={aditionalDocument}
            setAditionalDocument={setAditionalDocument}
          />

          <CreateOrderSubmit
            title={t("shipper.buttons.Get a quote")}
            loading={createOrderLoading}
            onClick={() => submitHandler()}
          />
        </div>
      </section>
    </Wrapper>
  );
};

export default CreateRoadOrder;
