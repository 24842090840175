import React, { useEffect } from "react";
import "./FavoriteRoutesModalItem.scss";

import { useTranslation } from "react-i18next";
import { IoRemoveCircleOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";

import {
  deleteLocation,
  resetDeleteLocation,
} from "../../../../../store/favoriteLocations/favoriteLocationsSlice";

const FavoriteRoutesModalItem = ({
  isEditActive,
  item,
  isClose,
  setPickUpInput,
  setDropOffInput,
  setPickUp,
  setDropOff,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deleteLocationResult } = useSelector((state) => state.favoriteLocations);

  const getLocationPart = (city, country) => (city ? `${city}, ` : "") + (country || "");
  const Alocation = getLocationPart(item.from_city, item.from_country);
  const Blocation = getLocationPart(item.to_city, item.to_country);
  const location = `${Alocation} - ${Blocation}`;

  const deleteHandler = () => {
    dispatch(deleteLocation(item.id));
  };

  const routeClickHandler = () => {
    setPickUpInput(item?.from_formatted_address);
    setDropOffInput(item?.to_formatted_address);
    setPickUp(item?.from_formatted_address);
    setDropOff(item?.to_formatted_address);
    isClose();
  };

  useEffect(() => {
    if (deleteLocationResult) {
      dispatch(resetDeleteLocation());
    }
  }, [dispatch, deleteLocationResult]);

  return (
    <li id='FavoriteRoutesModalItem'>
      <div className='FavoriteRoutesModalItemLeft'>
        <h1 onClick={() => routeClickHandler()}>{location}</h1>
        <p>
          {item.available_orders} {t("tms.desc.new order")}
        </p>
      </div>
      <div className='FavoriteRoutesModalItemRight'>
        <div
          className={
            isEditActive ? "FavoriteRoutesModalItemIcon show" : "FavoriteRoutesModalItemIcon"
          }
          onClick={() => deleteHandler()}
        >
          <IoRemoveCircleOutline />
        </div>
      </div>
    </li>
  );
};

export default FavoriteRoutesModalItem;
