import React, { useState, useEffect } from "react";
import "./SignUpShipperForm.scss";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { signupStep1, resetSignupStep1 } from "../../../../store/auth/authSlice";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Button from "../../../Form/Button/Button";
import Input from "../../../Form/Inputs/Input";
import Label from "../../../Form/Label/Label";
import ErrorText from "../../../Form/ErrorText/ErrorText";
import SelectCountry from "../../../Form/Select/Components/SelectCountry/SelectCountry";
import PhoneInput from "../../../Form/Inputs/PhoneInput/PhoneInput";
import NotHaveAccount from "./../../../Form/NotHaveAccount/NotHaveAccount";

const SignUpShipperForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { signupStep1Loading, signupStep1Result, signupStep1Error } = useSelector(
    (state) => state.auth
  );

  const { countryId, phoneIndexValueId } = useSelector((state) => state.settings);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (signupStep1Result) {
      const url = `/register/shipper/auth?firstName=${firstName}&lastName=${lastName}&countryId=${countryId}&phoneIndex=${phoneIndexValueId}&phone=${phone}`;
      navigate(url);
      dispatch(resetSignupStep1());
    }
  }, [
    dispatch,
    navigate,
    signupStep1Result,
    firstName,
    lastName,
    countryId,
    phone,
    phoneIndexValueId,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetSignupStep1());
    };
  }, [dispatch]);

  const submitHandler = async () => {
    let data = {
      type_id: 3,
      branch_id: countryId,
      first_name: firstName,
      last_name: lastName,
      phone_index_id: phoneIndexValueId,
      phone: phone,
    };
    dispatch(signupStep1(data));
  };

  return (
    <AuthFormWrapper>
      <form id='SignUpShipperForm' className='SignUpShipperForm'>
        <FormTitle title={t("landing.auth.titles.Welcome to the future of logistics")}>
          <span>{t("landing.auth.titles.It just take 2 minutes to set up")}</span>
        </FormTitle>
        <ProgressBar />
        <div className='group'>
          <div>
            <Label text={t("landing.auth.form.First name")} id='FirstName' />
            <Input
              value={firstName}
              setValue={setFirstName}
              placeholder={t("landing.auth.form.Enter first name")}
              id='FirstName'
              error={signupStep1Error?.errors?.first_name?.[0] ?? null}
            />
            <ErrorText error={signupStep1Error?.errors?.first_name?.[0] ?? null} />
          </div>
          <div>
            <Label text={t("landing.auth.form.Surname")} id='Surname' />
            <Input
              value={lastName}
              setValue={setLastName}
              placeholder={t("landing.auth.form.Enter surname")}
              id='Surname'
              error={signupStep1Error?.errors?.last_name?.[0] ?? null}
            />
            <ErrorText error={signupStep1Error?.errors?.last_name?.[0] ?? null} />
          </div>
          <div>
            <Label text={t("landing.auth.form.Phone number")} id='PhoneNumber' />
            <PhoneInput
              id='PhoneNumber'
              value={phone}
              setValue={setPhone}
              placeholder={t("landing.auth.form.Enter Phone number")}
              error={
                (signupStep1Error?.errors?.phone_index_id?.[0] ?? null) ||
                (signupStep1Error?.errors?.phone?.[0] ?? null)
              }
            />
            <ErrorText error={signupStep1Error?.errors?.phone_index_id?.[0] ?? null} />
            <ErrorText error={signupStep1Error?.errors?.phone?.[0] ?? null} />
          </div>
          <div>
            <Label text={t("landing.auth.form.Country")} />
            <SelectCountry
              placeholder={t("landing.auth.form.Choose Country")}
              error={signupStep1Error?.errors?.branch_id?.[0] ?? null}
            />
            <ErrorText error={signupStep1Error?.errors?.branch_id?.[0] ?? null} />
          </div>
        </div>
        <Button
          loading={signupStep1Loading}
          title={t("landing.auth.form.Next")}
          onClick={() => {
            submitHandler();
          }}
        />
        <NotHaveAccount
          desc={t("landing.auth.titles.Already have an account?")}
          link='/login'
          text={t("landing.auth.form.Sign in here")}
        />
      </form>
    </AuthFormWrapper>
  );
};

export default SignUpShipperForm;
