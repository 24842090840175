import React, { useEffect } from "react";
import DSelect from "../DSelect";

import { useSelector, useDispatch } from "react-redux";

import { resetUpdateUserLanguage, updateUserLanguage } from "../../../../store/user/userSlice";
import { setLang } from "../../../../store/additional/lang/langSlice";

const DLanguageSelect = ({ width }) => {
  const dispatch = useDispatch();

  const { getLanguagesSelect } = useSelector((state) => state.lang);
  const { updateUserLanguageResult, updateUserLanguageLoading } = useSelector(
    (state) => state.user
  );
  const { user } = useSelector((state) => state.auth);

  const currentValueLang =
    getLanguagesSelect && getLanguagesSelect.find((item) => item.value.key === user?.language);

  useEffect(() => {
    if (updateUserLanguageResult) {
      dispatch(resetUpdateUserLanguage());
    }
  }, [dispatch, updateUserLanguageResult]);

  return (
    <div id='DLanguageSelectContainer' style={{ width: width }}>
      <DSelect
        id={"DLanguageSelect"}
        value={currentValueLang}
        setValue={(lang) => {
          dispatch(updateUserLanguage({ language: lang.value.key }));
          setTimeout(() => {
            dispatch(setLang(lang.value.key));
          }, 500);
        }}
        placeholder='Languages'
        loading={updateUserLanguageLoading}
        options={getLanguagesSelect ? getLanguagesSelect : []}
      />
    </div>
  );
};

export default DLanguageSelect;
