import React, { Fragment, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { getUser } from "./../store/auth/authSlice";
import { getLanguages } from "../store/additional/lang/langSlice";
import { getContact, getContacts } from "../store/additional/contact/contactSlice";
import {
  getCountries,
  getPhoneIndexes,
  getCurrencies,
  getAllCountries,
  getGeolocationCountry,
  setPhoneIndexValueOnlyState,
  setCountryIdOnlyState,
} from "../store/additional/settings/settingsSlice";
import { getTrailerTypesRoad, getTrailerTypesOcean } from "../store/trailerType/trailerTypeSlice";
import { getRoutesRoadList } from "../store/routes/routesSlice";
import { getBankAccounts } from "../store/user/bankAccounts/bankAccountsSlice";

import AppLanding from "./AppLanding";
import AppDashboard from "./AppDashboard";
import Loading from "./../componentsAdditional/Loading/Loading";
import AppBids from "./AppBids";

const Main = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { userLoading, verify, userError, access_token } = useSelector((state) => state.auth);
  const { updateUserResult, verifyResult } = useSelector((state) => state.user);
  const { createBankAccountResult, updateBankAccountResult, deleteBankAccountResult } = useSelector(
    (state) => state.bankAccounts
  );
  const { createContactResult, updateContactResult, deleteContactResult } = useSelector(
    (state) => state.userContacts
  );
  const { uploadCompanyLogoResult, uploadProfileAvatarResult } = useSelector(
    (state) => state.upload
  );
  const { updateVatResult } = useSelector((state) => state.user);

  const {
    getGeolocationCountryResult,
    phoneIndexValueId,
    getPhoneIndexesResult,
    countryId,
    getCountriesResult,
  } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(getLanguages());
    dispatch(getCountries());
    dispatch(getPhoneIndexes());
    dispatch(getAllCountries());
    dispatch(getGeolocationCountry());
    dispatch(getTrailerTypesRoad());
    dispatch(getTrailerTypesOcean());
    dispatch(getRoutesRoadList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBankAccounts());
  }, [dispatch, createBankAccountResult, updateBankAccountResult, deleteBankAccountResult]);

  useEffect(() => {
    // if (access_token) {
    dispatch(getUser());
    // }
  }, [
    dispatch,
    // access_token,
    updateUserResult,
    verifyResult,
    createContactResult,
    updateContactResult,
    deleteContactResult,
    uploadCompanyLogoResult,
    uploadProfileAvatarResult,
    updateVatResult,
  ]);

  useEffect(() => {
    if (userError && userError.status === 429) {
      alert("Too many requests");
    }
  }, [userError]);

  useEffect(() => {
    const { country } = getGeolocationCountryResult || {};
    if (!phoneIndexValueId && country && getPhoneIndexesResult) {
      const findPhoneIndex = getPhoneIndexesResult?.find((i) => i.code === country)?.id ?? 83;
      dispatch(setPhoneIndexValueOnlyState(findPhoneIndex));
    }
  }, [dispatch, phoneIndexValueId, getGeolocationCountryResult, getPhoneIndexesResult]);

  useEffect(() => {
    const { country } = getGeolocationCountryResult || {};
    if (!countryId && country && getCountriesResult) {
      const findBranchIndex = getCountriesResult?.find((i) => i.country.code === country)?.id ?? 1;
      dispatch(setCountryIdOnlyState(findBranchIndex));
    }
  }, [dispatch, getCountriesResult, countryId, getGeolocationCountryResult]);

  useEffect(() => {
    if (countryId) {
      dispatch(getContact());
      dispatch(getContacts());
    }
  }, [dispatch, countryId]);

  useEffect(() => {
    if (verify) {
      dispatch(getCurrencies());
    }
  }, [dispatch, verify]);

  if (pathname.includes("/bids/partner/")) {
    return <AppBids />;
  }

  if (access_token && userLoading) {
    return <Loading />;
  }

  return <Fragment>{verify ? <AppDashboard /> : <AppLanding />}</Fragment>;
};

export default Main;
