import React, { Fragment, useState } from "react";
import "./OrdersTableItem.scss";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { FiMoreVertical, FiEdit2, FiTrash2 } from "react-icons/fi";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import TrailerField from "../../../Form/TableFields/TrailerField/TrailerField";
import RouteField from "./../../../Form/TableFields/RouteField/RouteField";
import PriceField from "../../../Form/TableFields/PriceField/PriceField";
import RowField from "../../../Form/TableFields/RowField/RowField";
import NameField from "../../../Form/TableFields/NameField/NameField";
import TextField from "./../../../Form/TableFields/TextField/TextField";
import DateField from "../../../Form/TableFields/DateField/DateField";
import MoreField from "../../../Form/TableFields/MoreField/MoreField";
import OrderStatusField from "../../../Form/TableFields/OrderStatusField/OrderStatusField";
import PartnerField from "./../../../Form/TableFields/PartnerField/PartnerField";
import DriverPhoneField from "../../../Form/TableFields/DriverPhoneField/DriverPhoneField";
import DeleteOrder from "./../../../Orders/DeleteOrder/DeleteOrder";

const OrdersTableItem = ({ order }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <RowField
        style={{
          borderLeft: `4px solid ${order?.color ? order?.color : "transparent"}`,
        }}
      >
        <NameField label={order?.tracking || "-"} to={`/dashboard/tms/orders/${order.id}`} />
        <OrderStatusField status={order?.statusTms ?? null} />
        <TextField max={30} label={order?.client?.name ?? "Cargon"} />
        <RouteField order={order} />

        {!order?.transports?.find((i) => i?.truck) && !order.partner ? (
          <DriverPhoneField driver={null} />
        ) : order?.partner ? (
          <PartnerField label={order?.partner?.name || t("tms.tables.noInfo.no partner")} />
        ) : (
          <DriverPhoneField
            driver={{
              name: order?.transports?.[0]?.driver || "",
              phone: order?.transports?.[0]?.phone || "",
            }}
          />
        )}

        <TrailerField
          icon={order?.trailer_type?.icon}
          trailerType={order?.cargos[0]?.trailerType?.name ?? "-"}
          volume={order?.cargos[0]?.trailersize?.label ?? "-"}
        />
        <DateField date={order?.cargos[0]?.pickup_date ?? null} />
        <PriceField
          number={order?.total_revenue ?? 0}
          symbol={order?.revenue_currency.symbol ?? "-"}
        />
        <MoreField>
          <div className='icon' onClick={handleClick}>
            <FiMoreVertical />
          </div>
          <Menu
            id='OrdersTableItemMore'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                navigate(`/dashboard/tms/orders/edit/${order.id}`);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <FiEdit2 />
                {t("tms.tables.buttons.Edit")}
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                handleClose();
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "red",
                }}
              >
                <FiTrash2 />
                {t("tms.tables.buttons.Delete")}
              </div>
            </MenuItem>
          </Menu>
        </MoreField>
      </RowField>

      {openDeleteModal && (
        <DeleteOrder
          isOpen={openDeleteModal}
          isClose={() => setOpenDeleteModal(false)}
          orderId={order.id}
        />
      )}
    </Fragment>
  );
};

export default OrdersTableItem;
