import React from "react";
import "./FiltersActiveStatusSelect.scss";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import Select from "react-select";

const FiltersActiveStatusSelect = ({ value, setValue }) => {
  const { t } = useTranslation();

  const { getTMSStatusesResult } = useSelector((state) => state.orderStatuses);

  const options = getTMSStatusesResult
    ?.filter((status) => status.id !== 5 && status.id !== 6)
    ?.map((status) => {
      return {
        value: status.id,
        label: status.name,
      };
    });
  return (
    <div id='FiltersActiveStatusSelect'>
      <Select
        classNamePrefix='select'
        name='FiltersActiveStatusSelect'
        className='selectComponent'
        options={options || []}
        placeholder={t("tms.filters.placeholders.Status")}
        value={value}
        onChange={(state) => setValue(state)}
        isClearable={true}
        isSearchable={true}
        noOptionsMessage={() => (
          <div>{t("tms.filters.others.No status found")}</div>
        )}
      />
    </div>
  );
};

export default FiltersActiveStatusSelect;
