import React, { useState, useEffect, Fragment } from "react";
import "./MakeBidModal.scss";

import { useTranslation } from "react-i18next";
import { MdOutlineLocalOffer } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

import { biddingBoard, resetBiddingBoard } from "../../../../store/loadBoards/loadBoardsSlice";

import DModal from "./../../../Modal/DModal/DModal";
import DCurrencyInput from "../../../Form/DCurrencyInput/DCurrencyInput";
import DSelect from "../../../Form/DSelect/DSelect";
import DInputs from "../../../Form/DInputs/DInputs";
import DPaymentSubOptions from "../../../Form/DSelect/DPaymentSubOptions/DPaymentSubOptions";

const MakeBidModal = ({ item, isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { getCurrenciesSelect } = useSelector((state) => state.settings);
  const { biddingBoardLoading, biddingBoardResult, biddingBoardError } = useSelector(
    (state) => state.loadBoards
  );
  const { getPaymentTypeLoading, getPaymentTypeSelect } = useSelector(
    (state) => state.orderSettings
  );

  const [bid, setBid] = useState("");
  const [currencyValue, setCurrencyValue] = useState(
    {
      label: user.currency.code,
      value: user.currency,
    } || null
  );
  const [payMethod, setPayMethod] = useState(null);
  const [payMethodSub, setPayMethodSub] = useState(null);
  const [payMethodAmount, setPayMethodAmount] = useState("");
  const [payMethodAmountCurrency, setPayMethodAmountCurrency] = useState(null);
  const [payConsignationPeriod, setPayConsignationPeriod] = useState("");

  // select default payment type
  useEffect(() => {
    if (getPaymentTypeSelect) {
      setPayMethod(getPaymentTypeSelect[0]);
    }
  }, [getPaymentTypeSelect]);

  // select default user currency value
  useEffect(() => {
    if (getCurrenciesSelect && user.currency && user.currency.id) {
      const findValue = getCurrenciesSelect.find((item) => item.value.id === user.currency.id);

      setPayMethodAmountCurrency(findValue);
    }
  }, [getCurrenciesSelect, user.currency]);

  const submitHandler = () => {
    const data = {
      id: item.id,
      amount: bid,
      currency_id: currencyValue.value.id,
      payment: {
        type_id: payMethod ? payMethod.value.id : null,
        days: payConsignationPeriod.length > 0 ? +payConsignationPeriod : null,
        option_type: payMethodSub ? payMethodSub.value.id : null,
        amount: payMethod && payMethod.value.id === 2 ? payMethodAmount : null,
        currency_id:
          payMethod && payMethod.value.id === 2 && payMethodAmountCurrency
            ? payMethodAmountCurrency.value.id
            : null,
      },
    };

    dispatch(biddingBoard(data));
  };

  useEffect(() => {
    if (biddingBoardResult) {
      dispatch(resetBiddingBoard());
      isClose();
    }
  }, [dispatch, isClose, biddingBoardResult]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      title={t("tms.modals.titles.Place your bid")}
      submitText={t("tms.modals.buttons.Submit")}
      icon={<MdOutlineLocalOffer />}
      loading={biddingBoardLoading}
      onClick={submitHandler}
    >
      <div id='LoadBoardMakeBidModal'>
        <p>{t("tms.modals.desc.You will see your bid status via my bids page")}</p>
        <div className='MakeBidModalGroup'>
          <DCurrencyInput
            id='MakeBidModalDCurrencyInput'
            value={bid}
            setValue={setBid}
            currencyValue={currencyValue}
            setCurrencyValue={setCurrencyValue}
            placeholder={t("tms.modals.placeholder.Enter your bid")}
            label={t("tms.modals.labels.Your bid")}
            required
            error={biddingBoardError?.errors?.amount?.[0]}
          />
          <DSelect
            required
            id='selectPaymentMethodMakeBidModal'
            label={t("tms.modals.labels.Payment method")}
            placeholder={t("tms.modals.placeholder.Payment method")}
            options={getPaymentTypeSelect ? getPaymentTypeSelect : []}
            value={payMethod}
            setValue={(e) => {
              setPayMethod(e);
              setPayMethodSub(null);
              setPayConsignationPeriod("");
              setPayMethodAmount("");
            }}
            loading={getPaymentTypeLoading}
            error={null}
          />

          {payMethod && (
            <Fragment>
              {payMethod.value.id === 2 && (
                <div className='groupItem'>
                  <DCurrencyInput
                    id='PaymentMethosAmount'
                    label={t("tms.forms.labels.PrepaymentAmount")}
                    placeholder={t("tms.forms.placeholder.Enter prepayment amount")}
                    value={payMethodAmount}
                    setValue={setPayMethodAmount}
                    currencyValue={payMethodAmountCurrency}
                    setCurrencyValue={setPayMethodAmountCurrency}
                    required
                    error={
                      biddingBoardError?.errors?.["payment.amount"]?.[0] ||
                      biddingBoardError?.errors?.["payment.currency_id"]?.[0]
                    }
                  />
                </div>
              )}

              {(payMethod.value.id === 2 || payMethod.value.id === 3) && (
                <div className='groupItem SubTypes'>
                  <DPaymentSubOptions
                    value={payMethodSub}
                    setValue={(e) => {
                      setPayMethodSub(e);
                      setPayConsignationPeriod("");
                    }}
                    error={biddingBoardError?.errors?.["payment.option_type"]?.[0]}
                  />
                </div>
              )}

              {(payMethod.value.id === 2 || payMethod.value.id === 3) &&
                payMethodSub &&
                payMethodSub.value.id === "AFTER_DROP_OFF" && (
                  <div className='groupItem'>
                    <DInputs
                      required
                      id='payConsignationPeriodAddOrderPayment'
                      value={payConsignationPeriod}
                      setValue={setPayConsignationPeriod}
                      type='number'
                      placeholder={t("tms.forms.placeholder.Number of days")}
                      label={t("tms.forms.labels.Consignation period")}
                      error={biddingBoardError?.errors?.["payment.days"]?.[0]}
                    />
                  </div>
                )}
            </Fragment>
          )}
        </div>
      </div>
    </DModal>
  );
};

export default MakeBidModal;
