import React, { useState, useEffect } from "react";
import "./PasswordReset.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { resetPassword, resetResetPassword } from "./../../../../store/auth/authSlice";

import Button from "../../../Form/Button/Button";
import Input from "../../../Form/Inputs/Input";
import ErrorText from "../../../Form/ErrorText/ErrorText";
import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import Label from "../../../Form/Label/Label";

const PasswordReset = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { phoneIndexValueId } = useSelector((state) => state.settings);

  const { resetPasswordLoading, resetPasswordResult, resetPasswordError } = useSelector(
    (state) => state.auth
  );

  const inputValue = searchParams.get("input-value");
  const method = searchParams.get("method");
  const otp = searchParams.get("value");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (resetPasswordResult) {
      const url = "/forgot-password/success";
      navigate(url);
      setPassword("");
      dispatch(resetResetPassword());
    }
  }, [dispatch, navigate, resetPasswordResult]);

  useEffect(() => {
    return () => {
      dispatch(resetResetPassword());
    };
  }, [dispatch]);

  const submitHandler = () => {
    let data =
      method === "EMAIL"
        ? {
            code: otp,
            email: inputValue,
            password,
            password_confirmation: password,
          }
        : {
            code: otp,
            phone_index_id: phoneIndexValueId,
            phone: inputValue,
            password,
            password_confirmation: password,
          };

    dispatch(resetPassword(data));
  };

  return (
    <AuthFormWrapper>
      <form id='PasswordReset' className='PasswordReset'>
        <FormTitle title={t("landing.auth.titles.Reset password")} center />
        <div>
          <Label
            text={t("landing.auth.form.New password")}
            id='EnterResetPassword'
            warning
            warningText={t("landing.form.warningText.password must contain minimum of 6 character")}
          />
          <Input
            id='EnterResetPassword'
            value={password}
            setValue={setPassword}
            placeholder={t("landing.auth.form.Enter new  password")}
            password
            error={
              (resetPasswordError?.errors?.password?.[0] ?? null) ||
              (resetPasswordError?.errors?.code?.[0] ?? null)
            }
          />
          <ErrorText error={resetPasswordError?.errors?.password?.[0] ?? null} />
          <ErrorText error={resetPasswordError?.errors?.code?.[0] ?? null} />
        </div>
        <Button
          loading={resetPasswordLoading}
          title={t("landing.auth.form.Change password")}
          onClick={() => {
            submitHandler();
          }}
        />
      </form>
    </AuthFormWrapper>
  );
};

export default PasswordReset;
